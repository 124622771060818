@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Poppins:wght@700&display=swap");

//design for the new quote component
input[type="number"] {
  -moz-appearance: textfield;
}

// @font-face {
//   font-family: "MainFont";
//   src: url("../fonts/SinkinSansLight.ttf");
// }
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
@font-face {
  font-family: "OpenSansItalic";
  src: local("OpenSansItalic"),
    url("../fonts/OpenSans-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSansRegular";
  src: local("OpenSansRegular"),
    url("../fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSansSemiBold";
  src: local("OpenSansSemiBold"),
    url("../fonts/OpenSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoSlabRegular";
  src: local("RobotoSlabRegular"),
    url("../fonts/RobotoSlab-Regular.ttf") format("truetype");
}

.font-family-open-sans-semi-bold {
  font-family: "MontserratRegular" !important;
}

.font-family-open-sans-italic {
  font-family: "OpenSansItalic" !important;
}

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
@font-face {
  font-family: "MontserratRegular";
  src: local("MontserratRegular"),
    url("../fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratMedium";
  src: local("MontserratMedium"),
    url("../fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratSemiBold";
  src: local("MontserratSemiBold"),
    url("../fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratBold";
  src: local("MontserratBold"),
    url("../fonts/Montserrat-Bold.ttf") format("truetype");
}

.font-family-montserrat-bold {
  font-family: "MontserratBold";
}

.font-family-montserrat-semi-bold {
  font-family: "MontserratSemiBold";
}

.font-family-montserrat-medium {
  font-family: "MontserratMedium";
}

.font-family-montserrat-regular {
  font-family: "MontserratRegular";
}

html body {
  max-width: 100%;
  overflow-x: hidden;
}

.color-blue {
  color: #427cbc;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

.ml-10 {
  margin-left: 10px;
}

input {
  padding: 0.5rem;
}

.per5 {
  width: 5%;
  max-width: 5%;
}

.per7 {
  width: 6%;
  max-width: 6%;
}

.per20 {
  width: 20%;
  max-width: 20%;
}

.per10 {
  max-width: 10%;
  width: 10%;
}

body {
  font-family: "MontserratRegular";
  -webkit-font-smoothing: antialiased;
  color: black;
  // font-size: 13.7px;
  font-size: 16px;
}

.btn-popupdownload {
  color: #fff;
  background-color: #427cbc;
  font-weight: bold;
  border: none;
  border-radius: 100px;
  padding: 10px 20px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: auto;
  margin-bottom: 10px;
}

.btn-popupdownload:hover {
  background-color: rgb(255, 196, 35);
  /* Lighter hover color */
  text-decoration: none;
  color: black;
}

.btn-popupdownload:focus,
.btn-popupdownload:active {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(216, 166, 2, 0.5);
}

.home1 {
  word-break: break-all;
  // font-family: "Rokkitt", serif;
  font-weight: 400;
  line-height: 1.1;

  .header {
    width: 100%;

    .logos-container {
      padding: 0.4rem 0;
      margin-left: 2.5rem;

      .solve-logo-container {
        height: 4.65rem;

        img {
          max-height: 100%;
        }
      }

      .insurecomp-logo-container {
        height: 3.28rem;
        margin-top: -1.4rem;

        img {
          max-height: 100%;
        }
      }
    }
  }

  .image-block {
    cursor: pointer;
    position: relative;
    height: 22rem;
    overflow: hidden;

    img {
      max-width: 100%;
    }

    .image-text-container {
      padding: 1rem 0;
      background: rgba(24, 54, 80, 0.6);
      position: absolute;

      .image-text {
        font-size: 3.125rem;
        font-weight: bolder;
        color: white;
      }
    }
  }

  .stats-1 {
    width: 100%;
    margin-bottom: 4rem;

    .stat-1-1,
    .stat-1-2 {
      flex: 1;

      span {
        font-weight: bolder;
        font-size: 2.5rem;
      }

      img {
        height: 5.5rem;
        max-height: 100%;
        max-width: 100%;
      }
    }

    .stat-1-1 {
      img {
        height: 6rem;
      }

      span {
        margin-left: 1.4rem;
      }
    }
  }

  .confirm-quote {
    width: 100%;

    button {
      border-radius: 10px;
      box-shadow: 2px 4px 10px #23b4bd;
      font-size: 3.125rem;
      font-weight: 700;
      color: white;
      background-color: #23b4e8;
      width: 35rem;
      padding: 0.5rem 0;
    }
  }

  .services {
    padding: 3rem 0 4rem 0;

    .services-header {
      font-size: 4rem;
      font-weight: bolder;
      margin-bottom: 3rem;
    }

    .services-list-block {
      .services-list-1 {
        margin-bottom: 3rem;

        .services-1-1,
        .services-1-2,
        .services-1-3 {
          flex: 1;

          span {
            font-weight: bolder;
            font-size: 2.5rem;
          }

          img {
            margin-bottom: 1.5rem;
            height: 5.5rem;
            max-height: 100%;
            max-width: 100%;
          }
        }
      }

      .services-list-2 {
        .services-2-1,
        .services-2-2,
        .services-2-3 {
          flex: 1;

          span {
            font-weight: bolder;
            font-size: 2.5rem;
          }

          img {
            margin-bottom: 1.5rem;
            height: 5.5rem;
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }

  .stats-banner {
    background-color: #183650;
    padding: 2.5rem 0;

    .stats-banner-item-1,
    .stats-banner-item-2,
    .stats-banner-item-3 {
      flex: 1;
      font-size: 2.5rem;
      font-weight: bolder;
      color: white;
    }
  }

  .testimonials {
    font-size: 2rem;
    margin: 2rem;

    .testimonials-text {
      font-style: italic;
    }
  }
}

.logout-div {
  margin-right: 2.5rem;
  font-size: 16px;
}

.logout-div:hover {
  text-decoration: underline;
  cursor: pointer;
}

.home2 {
  // font-family: "Poppins", serif;
  font-weight: 400;
  line-height: 1.1;

  .footer {
    width: 100%;
    height: 3rem;
    background-color: #183650;
    font-size: 2rem;

    a,
    span {
      margin-right: 2.5rem;
      color: white;
    }

    span:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .header {
    width: 100%;

    .logos-container {
      padding: 0.4rem 0;
      margin-left: 2.5rem;

      .solve-logo-container {
        height: 6.65rem;

        img {
          max-height: 100%;
        }
      }

      .insurecomp-logo-container {
        height: 3.28rem;
        margin-top: -1.4rem;

        img {
          max-height: 100%;
        }
      }
    }
  }

  .image-block {
    cursor: pointer;
    position: relative;
    height: 22rem;
    overflow: hidden;

    img {
      max-width: 100%;
    }

    .image-text-container {
      padding: 1rem 0;
      background: rgba(24, 54, 80, 0.6);
      position: absolute;

      .image-text {
        font-size: 3.125rem;
        font-weight: bolder;
        color: white;
      }
    }
  }

  .stat-2-1 {
    margin-right: 2.5rem;
    word-break: break-word;

    span {
      width: inherit;
      font-weight: bolder;
      font-size: 2.5rem;
    }

    img {
      height: 5.5rem;
      max-height: 100%;
      max-width: 100%;
    }
  }

  .confirm-quote {
    width: 100%;

    button {
      border-radius: 10px;
      box-shadow: 2px 4px 10px #eea236;
      font-size: 3.125rem;
      font-weight: 700;
      color: white;
      background-color: #ffd13f;
      border-color: #eea236;
      width: 35rem;
      padding: 0.5rem 0;
    }
  }

  .stats-banner {
    background-color: #183650;
    padding: 2.5rem 0;

    .stats-banner-item-1,
    .stats-banner-item-2,
    .stats-banner-item-3 {
      flex: 1;
      font-size: 2.5rem;
      font-weight: bolder;
      color: white;
    }
  }

  .testimonials {
    font-size: 2rem;
    margin: 2rem;

    .testimonials-text {
      font-style: italic;
    }
  }
}

class-desc-select .class-desc-select__menu {
  height: fit-content;
  width: fit-content;
  max-width: 500px;
  min-width: -webkit-fill-available;
  min-width: -moz-available;
}

.contentHolder-history {
  min-height: calc(100vh - 15.75rem);
}

@mixin cmprQts {
  //style of the background image:
  //background-image: url("../images/backimage4.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 95%;
  background-attachment: sticky;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .quote-table {
    .quote-table-company-name {
      padding-right: calc(2rem + 25px);
    }

    .head-row {
      align-items: center;
      justify-content: space-between;
    }

    .hr1 {
      color: #2c2e33;
      font-size: 26px;
      font-weight: 500;

      &.quote-table-company-name {
        // font-family: "Overpass", sans-serif;
        font-size: 20px;
        font-weight: 600;
      }
    }

    .hr2 {
      color: #2c2e33;
      width: 70%;
      margin-top: 1rem;
      margin-bottom: 1rem;

      span {
        color: #5f636b;
      }
    }

    .hr3 {
      margin-bottom: 1.5rem;
      color: #2c2e33;
    }

    .body-row {
      padding-left: 6px;
      margin-top: 2rem;

      // justify-content: left;
      .table {
        margin-bottom: 7rem;
        position: relative;
        right: 2rem;
        border-collapse: separate;
        border-spacing: 25px 0px;

        thead {
          th {
            color: #2c2e33;
            position: relative;
            right: 0.6rem;
            font-size: 16px;
          }
        }

        tbody {
          padding-left: 5px;
          padding-right: 5px;
          font-size: 18px;

          td,
          th {
            width: 25%;
            border: 1px solid white;
            background-color: white;
            color: #2c2e33;
            font-size: 18px;
            font-weight: normal;

            div {
              height: 100%;

              label {
                margin-bottom: 0;
              }
            }
          }

          td {
            label {
              font-size: 22px;
              font-weight: 800;
              color: #1463ac;
              margin-bottom: 0;
            }

            .best {
              font-size: 14px;
              margin-left: 1rem;
              color: #038b00;
            }

            .higher {
              font-size: 14px;
              margin-left: 1rem;
              color: #2c2e33;
            }

            .uw {
              font-size: 14px;
              margin-left: 1rem;
            }

            input {
              margin: 0.5rem;
            }
          }
        }
      }
    }
  }
}

.new-navbar {
  // margin-bottom: 1rem;
  height: 2rem;
  background-color: #003764;
  color: white;
  font-weight: 600;
  font-size: 16px;

  .pride-logo {
    margin-bottom: -10px;
    margin-left: 2.2rem;
  }

  .insurecomp-logo {
    margin-right: 2.2rem;
  }

  .forBig {
    margin-left: auto;
  }

  .new-logo {
    // margin-top: 0.5rem;
    height: 3.375rem;
    // margin-left: 2.2rem;
  }

  .boxed-logo {
    background-color: #fff;
    padding: 20px;
    height: 125px;
    margin: 5px 20px;
    border-radius: 5px;
    -webkit-box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    margin-left: 40px;
  }

  button {
    border: none;
    color: white;
    background: none;
    font-weight: 600;
  }

  span {
    color: white;
    font-weight: 600;
  }

  ul {
    // border: 1px solid red;
    li {
      // padding-bottom: 10px;

      margin-right: 1rem;
      margin-left: 1rem;
    }
  }

  .subnav {
    background-color: #0068c9;
  }
}

.secDesc {
  text-align: center;
  font-size: 16px;
  margin-bottom: 30px;
}

#small-loader {
  width: fit-content;
  height: fit-content;

  /* margin-top: 1rem; */
  > img {
    width: 2rem;
  }
}

#rate1,
#rate2 {
  margin-bottom: 5px;
}

#compareQuote {
  .btnSubmits {
    margin-top: 2rem;
    width: 210px;
    height: 40px;
    border-radius: 5px;
    background-color: #ffd13f;
    border-color: #eea236;
    box-shadow: none;
    color: white;
    // font-size: 14px;
    font-weight: 800;
  }

  .btnView {
    width: 210px;
    height: 40px;
    border-radius: 5px;
    background-color: #1463ac;
    margin-right: 1rem;
    margin-top: 2rem;
    // font-size: 14px;
    font-weight: 800;
  }

  // .iFrameContainer {
  //   width: 100%;
  //   height: 700px;
  //   margin-top: 1rem;
  //   justify-content: center;
  //   margin-bottom: 3.5rem;
  //   iframe {
  //     width: 100%;
  //     height: 100%;
  //   }
  // }
}

.errMsg {
  color: red;
}

.admin-dashboard {
  .discount-body {
    input,
    div {
      font-size: 12px;
    }
  }
}

.tab1,
.tab2,
.tab3,
.tab4 {
  thead {
    tr:first-child th {
      width: 10px;
    }
  }

  tbody {
    tr:first-child td {
      width: 10px;
    }
  }
}

.tab1,
.tab2,
.tab3,
.tab4 {
  tbody tr:first-child td:first-child {
    // background-image: url("../images/filterimage.png");
    background-image: url("../images/filter_excel.png");
    background-repeat: no-repeat;
    background-size: 70% 80%;
    top: 5px !important;
    left: 4px !important;
    position: relative !important;
  }
}

.sub_tab1 tbody tr:first-child td:first-child {
  background-image: none;
  top: 0 !important;
  left: 4px !important;
  position: none !important;
}

.sub_tab2 tbody tr:first-child td:first-child {
  background-image: none;
  top: 0 !important;
  left: 4px !important;
  position: none !important;
}

.sub_tab3 tbody tr:first-child td:first-child {
  background-image: none;
  top: 0 !important;
  left: 4px !important;
  position: none !important;
}

.sub_tab4 tbody tr:first-child td:first-child {
  background-image: none;
  top: 0 !important;
  left: 4px !important;
  position: none !important;
}

.preUWDashboard {
  thead {
    tr:first-child th {
      width: 10px;
    }
  }

  tbody {
    tr:first-child td {
      width: 10px;
    }
  }
}

.header {
  background-color: white;
  width: 100%;

  .logos-container {
    padding: 0.4rem 0;
    margin-left: 2.5rem;

    .solve-logo-container {
      height: 5rem;

      img {
        max-height: 100%;
      }
    }

    .insurecomp-logo-container {
      height: 2.47rem;
      margin-top: -1rem;

      img {
        max-height: 100%;
      }
    }
  }
}

.stat-2-1 {
  margin-right: 2.5rem;
  word-break: break-word;

  span {
    width: max-content;
    font-weight: bolder;
    font-size: 1rem;
  }

  img {
    height: 5.5rem;
    max-height: 100%;
    max-width: 100%;
  }
}

// .upload-docs-action {
//   border-radius: 7.2px;
//   background-color: #ffd13f;
//   border-color: #eea236;

//   color: white;
//   font-size: 1rem;
//   font-weight: 800;
// }

.newquote-mainbody {
  // font-family: "Rokkitt", serif;
  //global styles for quote main body

  background-color: #e6edf0;
  width: 100%;
  padding-left: 0;
  padding-right: 0;

  .forSmall {
    display: none;
  }

  .negative-margin-bottom {
    margin-bottom: -14px;
  }

  .upload-docs-action {
    border-radius: 7.2px;
    background-color: #427cbc;

    color: white;
    font-size: 1rem;
    font-weight: 800;
  }

  .newquote-content {
    #compPro {
      padding-left: 0;
      padding-right: 0;

      #compProfile {
        .table-undrques {
          .tableQuesCol {
            width: 85% !important;
          }
        }
      }
    }

    background-color: #fff5dc;

    //global styling for everything in the light blue background
    ul {
      padding-right: 20px;
      margin-top: 15px;

      li {
        // padding-bottom: 10px;
        color: black;

        a {
          text-decoration: none;
        }

        border-radius: 7.2px;
        // background-color: #427cbc !important;
        border-color: #ffc423;
        box-shadow: rgba(0, 0, 0, 0.3) 2.95px 2.95px 6.6px;
        margin-right: 16px;
        font-family: "Montserrat", sans-serif;
        font-size: 20px;
        font-weight: 550;
        padding: 1px 10px 1px 10px;
        background-color: #ffc423;
      }

      li:hover {
        background-color: #ffdb73;
        /* Lighter color on hover */
        border: 1px solid black;
      }

      .user-dropdown {
        display: flex;
        border-radius: 7px;
        border: 1px solid black;
        color: white;
        margin-right: 16px;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        font-weight: 550;
        padding: 1px 10px;
        background-color: transparent;
        justify-content: center;
        align-items: center;
        gap: 5px;

        &.dropdown-toggle {
          cursor: pointer;
        }
      }

      .logoutstyle {
        a {
          color: "black";
          font-weight: 550;
          font-size: 20px;
          font-weight: 550;
          cursor: pointer;
        }
      }
    }

    // padding-left: 1.5rem;
    // padding-right: 1.6rem;
    width: 90%;
    // margin-top: 1rem;
    padding-bottom: 2rem;

    // background-color: #e2f2ff;
    .emodDate,
    form select,
    form input[type="text"],
    form input[type="email"],
    form input[type="password"],
    form input[type="date"],
    form textarea {
      width: 100%;
      // font-size: 0.85em;
      border-radius: 4px;
      border: 1px solid white;
      background: white;
      color: black; //#2b2d31;
      font-weight: "normal";
    }

    input[type="checkbox"] {
      height: 16px !important;
      width: 16px;
      border-radius: 4px;
      border: solid 1px #979797;
      background-color: #ffffff;
    }

    //styling of the headers and section-headers:
    // this is styling for all the different stages
    // for changing any heading, please refer to this section only
    .formTitle {
      color: #2c2e33;
      // font-family: "Rubik", sans-serif;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 1.38;

      &.location-details-heading {
        font-weight: 400;
        font-size: 1.2rem;
      }

      &.compName {
        // font-family: Overpass;
        font-size: 20px;
        font-weight: 600;
        color: #2c2e33;
        // padding-left: 4rem;
      }

      &.title2 {
        width: 70%;
        display: inline-block;
      }

      &.title3 {
        font-size: 20px;
        font-weight: normal;
      }
    }

    .addressRate,
    .aggregate-heading,
    .stateAggName {
      // font-family: Overpass;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2b2d31;
    }

    //end of heading/subheading styles
    //styling of buttons:
    .transpButton {
      background-color: transparent;
      border-color: transparent;
    }

    .btnView {
      width: 210px;
      height: 40px;
      border-radius: 5px;
      background-color: #1463ac;
      margin-right: 1rem;
      margin-top: 2rem;
      // font-size: 14px;
      font-weight: 800;
    }

    .proceed-bind,
    .upload-docs {
      border-radius: 7.2px;
      background-color: #ffd13f;
      border-color: #eea236;
      // background-image: linear-gradient(to right, #10c03e, #0eaf84);
      // box-shadow: none;
      box-shadow: 2px 4px 12px #eea236;
      // border-color: transparent;
      color: white;
      font-size: 1rem;
      font-weight: 800;
    }

    .btnSubmits {
      // margin-top: 2rem;
      border-radius: 7.2px;
      background-color: #427cbc !important;
      border-color: #427cbc;
      // box-shadow: 2px 4px 12px #eea236;
      box-shadow: rgba(0, 0, 0, 0.3) 2.95px 2.95px 6.6px;
      color: white;
      font-size: 1rem;
      font-weight: 800;
    }

    // end of button styling
    //style for the progress bar at the top of every page
    // please refer to this section only for styling the progress bar
    //#3e9ccc
    .color-bar {
      margin-top: 1.5rem;
      margin-bottom: 25px;
      // margin-left: 1.2rem;
      width: 100%;
      height: 12px;
      border-radius: 8px;
      background-color: #8ab93f;
    }

    // end of progress bar styles
    //style for company profile tab
    #paymentQuote {
      span.errMsg {
        color: red;
        font-size: 11px;
      }
    }

    #compProfile,
    #locationPayrollContainer {
      // background-image: url("../images/backimage1.png");
      @media screen and (max-width: 992px) {
        .pink-header {
          .font-family-montserrat-bold {
            font-size: 18px;
            padding-left: 18px;
          }
        }

        .primary_contact_container {
          margin-right: 0 !important;
        }

        .row {
          display: flex;
          flex-direction: column;
        }

        #phone-number,
        #entity-type,
        #years-in-business,
        #expected-start-date,
        #street-1,
        #zip-1,
        #city-1,
        #state-1,
        #street-2,
        #zip-2,
        #city-2,
        #state-2 {
          width: 100% !important;
        }
      }

      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 95%;
      background-attachment: sticky;

      .class-desc-select {
        input {
          box-shadow: none !important;
        }
      }

      .pink-sign {
        font-weight: 800;
        margin-left: 15px;
        color: rgb(255, 255, 255);
        background-color: #c0224d;
        border-radius: 50%;
        width: 29px;
        height: 29px;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }

      .company-profile-container,
      .question-list-container,
      .location-payroll-container {
        margin-left: 100px;
        margin-right: 100px;
        margin-bottom: 5px;
      }

      .location-payroll-container {
        #perCKdollar {
          width: 6rem;
        }

        #adminPercent {
          width: 5rem;
        }

        #requested-pricing-radio-yes,
        #requested-pricing-radio-no {
          margin-inline: 10px;
          // align-self: baseline;
        }

        input[type="radio"] {
          width: 18px;
          height: 18px;
          display: grid;
          place-content: center;
          background-color: transparent;
          cursor: pointer;
        }

        input[type="radio"]::after {
          content: "";
          width: 0.65em;
          height: 0.65em;
          background-color: #427cbc;
          border-radius: 40%;
          transform: scale(0);
          transition: 2s transform ease-in-out;
          box-shadow: inset 1em 1em var(--form-control-color);
        }

        input[type="radio"]:checked::after {
          transform: scale(1);
          background-color: #427cbc;
          border: 1px solid #427cbc;
          box-shadow: #427cbc;
          transition: 2s transform ease-in-out;
        }
      }

      .question-list-container {
        td {
          padding-left: 0px;
        }
      }

      #sameAsPrimary-1 {
        box-shadow: none;
        border: 2px solid black !important;
        width: 14px !important;
      }

      #locSameAsPrimary-1 {
        box-shadow: none;
        border: 2px solid black !important;
        width: 13.5px !important;

        margin-bottom: 0.5rem;
      }

      .locSameAsPrimary-1-label {
        font-size: 16px;
      }

      .question-list-container {
        width: 85%;

        input[type="radio"] {
          min-width: 20px;
          width: 20px;
          height: 20px;
          display: grid;
          place-content: center;
          background-color: transparent;
          cursor: pointer;
        }

        input[type="radio"]:after {
          content: "";
          width: 0.65em;
          height: 0.65em;
          border-radius: 40%;
          transform: scale(0);
          transition: 2s transform ease-in-out;
          box-shadow: inset 1em 1em var(--form-control-color);
        }

        input[type="radio"]:checked:after {
          transform: scale(1);
          border: 1px solid #427cbc;
          box-shadow: #427cbc;
          transition: 2s transform ease-in-out;
        }

        // input[type="radio"]:after {
        //   width: 20px;
        //   height: 20px;
        //   border-radius: 20px;
        //   top: -3px;
        //   left: -1px;
        //   position: relative;
        //   background-color: #ffffff;
        //   content: "";
        //   display: inline-block;
        //   visibility: visible;
        //   border: 1px solid black;
        // }
        // input[type="radio"]:checked:after {
        //   width: 20px;
        //   height: 20px;
        //   border-radius: 20px;
        //   top: -3px;
        //   left: -1px;
        //   position: relative;
        //   background-color: #6e98e0;
        //   content: "";
        //   display: inline-block;
        //   visibility: visible;
        //   border: 1px solid black;
        // }
        // input[type="radio"]:after {
        //   transition: transform(0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75));
        //   transform: scale(0, 0);
        //   content: "";
        //   position: absolute;
        //   top: 0;
        //   left: rem(2);
        //   z-index: 1;
        //   width: 20px;
        //   height: 20px;
        //   background: #a04916;
        //   border-radius: 50%;
        // }
        // input[type="radio"]:checked:before {
        //   transform: scale(1, 1);
        // }
        // input[type="radio"]:after {
        //   content: "";
        //   position: absolute;
        //   top: rem(-4);
        //   left: rem(-2);
        //   width: 1rem;
        //   height: 1rem;
        //   background: #fff;
        //   border: 2px solid #f2f2f2;
        //   border-radius: 50%;
        // }
        .form-check-label {
          margin-left: 5px;
          font-size: 13px;
        }
      }

      .addLoc {
        margin-bottom: 18px;
      }

      .react-datepicker-wrapper {
        width: -webkit-fill-available;
        width: -moz-available;
        width: stretch;

        .react-datepicker__input-container {
          width: 100%;
        }
      }

      #healthQues,
      #otp {
        font-size: inherit;
      }

      .formTitle {
        margin-bottom: 34px;
        font-size: 24px;
        font-weight: bold;
      }

      .company-locations,
      .emod-ques-heading {
        margin-bottom: 30px;
      }

      .mandate {
        color: #919399;
      }

      small {
        color: #333333;
      }

      .form-group {
        margin-bottom: 38px;

        select,
        input {
          height: 35px;
          color: black;
          // font-size: 14px;
          padding-left: 5px;
          // box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
          box-shadow: 0.5px 1px 4px grey;
        }

        textarea {
          color: black;
          // font-size: 14px;
          padding-inline: 15px;
          // box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
          box-shadow: 0.5px 1px 4px grey;
        }

        label {
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          // font-size: 11px;
          color: #333;
          margin-bottom: 0;

          &.mandatory::after {
            content: "*";
            color: red;
            padding-left: 2px;
          }
        }

        p.errMsg {
          color: red;
          font-size: 11px;
        }
      }

      textarea {
        // padding-left: 5px;
        height: 123px;
      }

      #allLocations {
        margin-top: 6px;
      }

      .emod-ques {
        margin-top: 44px;
      }

      .address-delete {
        margin-top: 1.2rem;
      }

      .linkLike {
        position: relative;
        bottom: 20px;
      }

      #otpSendMsg,
      #otpSentMsg,
      #otpVerify,
      #verifySuc {
        display: none;
        font-size: 0.8em;
      }

      #otpSec,
      #resendOTP {
        display: none;
      }

      #emailStatus,
      #verifyStatus {
        margin-top: 1rem;
      }

      #timerOTP {
        font-size: 0.8rem;
      }

      .btn-link {
        padding-left: 0;
      }
    }

    #cmprRate {
      //style block for compare rate page/tab
      //styling for the background image
      background-image: url("../images/backimage2.png");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 100%;
      background-attachment: sticky;

      // .download-link-div {
      //   width: 20px;
      // }
      .formTitle {
        margin-bottom: 60px;
      }

      .title3 {
        margin-bottom: 25px;
      }

      .linkLike {
        margin-bottom: 37px;
      }

      .calcPremium {
        color: #919399;

        input {
          height: 36px;
          color: #2b2d31;
          font-size: 14px;
          padding-right: 5px;
        }
      }

      .rangeSlider {
        padding: 1.2rem;

        .row {
          // justify-content: space-around;
          margin: 0;
          width: 100%;
        }

        .premVal {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35%;
          height: 35px;
          border-radius: 20px;
          background-color: #2987de;
          font-size: 20px;
          font-weight: 800;
          color: #ffffff;

          span {
            position: relative;
            top: 2px;
          }
        }

        .barBG {
          margin-left: 1rem;
          margin-right: 1rem;
          margin-top: 1rem;
          width: 12px;
          height: 2px;
          background-color: #2c2e33;
        }
      }

      #attachSec {
        margin-top: 47px;

        .no-lossrun-affidavit-heading {
          color: #2b2d31;
          font-weight: "normal" !important;
          font-size: 14px;
        }

        .dropped-file {
          color: #4169ba;
        }

        .drop-zone {
          margin-top: 20px;
          margin-bottom: 44px;
          display: flex;
          background: white;
          opacity: 0.5;
          justify-content: center;
          align-items: center;
          height: 106px;
          width: 385px;
          border: 1px dashed black;

          span {
            color: #1463ac;
          }
        }

        .drop-info {
          margin-top: 20px;
        }
      }

      .grey-table-row {
        position: relative;
        right: 7px;
      }

      #claimTable {
        input {
          height: 36px;
          margin-bottom: "none";
          color: #2b2d31;
          font-size: 14px;
        }

        color: #919399;
        font-weight: "normal";
        border-collapse: separate;
        border-spacing: 20px 0px;

        .row-no-coverage {
          .no-coverage {
            width: 30%;
            margin-bottom: 20px;

            input {
              height: 12px;
              margin-bottom: 25px;
              margin-right: 5px;
            }
          }
        }
      }

      .btnSubmits {
        margin-top: 30px;
        margin-bottom: 36px;
      }

      .form-group {
        select,
        input {
          height: 35px;
          color: #2b2d31;
          // font-size: 14px;
          padding-left: 5px;
        }

        label {
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          // font-size: 11px;
          color: #333;
          margin-bottom: 0;

          &.mandatory::after {
            content: "*";
            padding-left: 2px;
          }
        }

        p.errMsg {
          color: red;
          font-size: 11px;
        }
      }

      #claimTable {
        .form-group {
          margin-bottom: 2px;
        }
      }
    }

    #undrQues {
      // style for the background image:
      // background-image: url("../images/backimage3.png");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 95%;
      background-attachment: sticky;

      label {
        font-weight: 200;
      }

      input[type="radio"] {
        width: 20px;
        height: 20px;
        display: grid;

        place-content: center;
        background-color: transparent;
        cursor: pointer;
      }

      input[type="radio"]:after {
        content: "";
        width: 0.65em;
        height: 0.65em;
        border-radius: 40%;
        background-color: #427cbc;

        transform: scale(0);
        // transition: 2s transform ease-in-out;
        // box-shadow: inset 1em 1em var(--form-control-color);
      }

      input[type="radio"]:checked:after {
        //  transform: scale(1);
        background-color: #427cbc;
        //  transition: 2s transform ease-in-out;
      }

      //style for the unerwriter questions and additional questions table:
      .heading-row {
        justify-content: space-between;

        .compName {
          margin-top: 2px;
          color: #2c2e33;
          font-size: 20px;
          font-weight: 600;
        }
      }

      .title2 {
        margin-bottom: 50px;
      }

      .table-undrques-container {
        margin-bottom: 3.5rem;
      }

      .table-undrques {
        margin-bottom: 0px !important;
      }

      .table-addnques,
      .table-undrques {
        thead {
          color: #2c2e33;
          font-size: 1.2rem;

          tr {
            th {
              padding: 1.7rem 0;
              border: none;

              .pink-header {
                margin-bottom: 0;
              }
            }
          }
        }

        tbody {
          tr {
            margin-bottom: 1rem;

            .tableQuesCol {
              width: 90%;
              // border: 1px solid green;
              padding-right: 30px;
              // margin-right: 20px;
            }

            .tableAns {
              width: 5%;
              padding-left: 2rem;

              // border: 1px solid red;
            }

            td {
              padding: 0.8rem 0;
              vertical-align: middle;

              &.tableAns {
                .form-check-inline {
                  margin-right: 0;
                }

                .radio-btn-no {
                  margin-left: 2.5rem;
                  width: 18px;
                  height: 18px;
                }

                .radio-btn-yes {
                  margin-left: 1rem;
                  width: 18px;
                  height: 18px;
                }

                .radiobtn {
                  margin-left: 4px;
                  position: relative;
                  bottom: 4px;
                }
              }

              &.tableQues {
                width: 80%;
              }

              &.tableRemarks {
                padding-right: 5px;

                input {
                  height: 27px;
                  border: 1px solid rgb(168, 164, 164);
                  background-color: rgb(233, 233, 233);
                  border-radius: unset;
                  text-align: center;
                  width: 115px;

                  &:focus {
                    background-color: white; // Set background color to white on focus
                  }
                }

                .hidden {
                  background-color: #a3a2a0;
                }
              }

              &.table-question {
                margin: 10px;
              }
            }
          }
        }
      }
    }

    .additional-questions {
      margin-bottom: 30px;
    }

    .table-addnques {
      .tableAns {
        width: 18%;
      }
    }

    .btn-undrques {
      margin-bottom: 2rem;
    }

    #cmprQts {
      @include cmprQts;

      #compareQuote {
        margin-top: 3rem;
      }

      .quote-table {
        button {
          color: white;
          font-family: "MontserratSemiBold";
          border-radius: 12px;
        }

        .peo-btn {
          font-size: 1.4rem;
          width: 9rem;
          height: 3.8rem;
          box-shadow: 0px 5px 12px grey;
          background-color: #8ab93f;
          // margin-left: 2.6rem;
        }

        .paygo-btn {
          font-size: 1.4rem;
          width: 9rem;
          height: 3.8rem;
          box-shadow: 0px 5px 12px grey;
          background-color: #feae01;
          margin-left: 2.5rem;
        }

        .quote-button-container {
          display: flex;
          justify-content: center;
          padding: 20px;

          .close-btn,
          .export-btn {
            box-shadow: 0px 5px 12px grey;
            background-color: #feae01;
          }

          .close-btn {
            width: 7rem;
          }
        }
      }

      #quote-table-box {
        table {
          th,
          td {
            border-left: none;
            border-right: none;
          }

          tbody {
            background-color: white;
          }
        }
      }

      .quote-table-box {
        box-shadow: 0.5px 1px 5px 3px grey;
      }

      .quote-table-box {
        background-color: white;
        padding: 1rem;

        .quote-button-container {
          display: flex;
          justify-content: center;
        }

        .table-wrapper {
          max-height: 400px;
        }

        thead {
          background-color: #3e9ccc;
        }

        th {
          background-color: #3e9ccc;
          color: white;
          text-align: center;
          vertical-align: middle;
          position: sticky;
          top: 0;
        }

        td {
          white-space: nowrap;
          // width: 50%;
          text-align: center;
          vertical-align: middle;
          max-width: 90px;
        }

        tr:nth-child(even) {
          background-color: #d6eeb3;
        }

        tr th:first-child {
          border-left: 4px solid #3e9ccc;
        }

        tr th:last-child {
          border-right: 4px solid #3e9ccc;
        }

        tr td:first-child {
          border-left: 4px solid #3e9ccc;
        }

        tr td:last-child {
          border-right: 4px solid #3e9ccc;
        }

        tbody:last-child {
          border-bottom: 4px solid #3e9ccc;
        }

        tbody:first-child {
          margin-top: 0px;
        }

        tbody:before {
          content: "@";
          display: block;
          line-height: 0px;
          text-indent: -999999px;
        }
      }

      .emod-ques,
      .historical-claims {
        margin-top: 3rem;
      }

      .emod-ques {
        input[type="radio"] {
          width: 20px;
          height: 20px;
          display: grid;
          place-content: center;
          background-color: transparent;
          cursor: pointer;
        }

        input[type="radio"]:after {
          content: "";
          width: 0.65em;
          height: 0.65em;
          border-radius: 40%;
          transform: scale(0);
          //  transition: 2s transform ease-in-out;
          box-shadow: inset 1em 1em var(--form-control-color);
        }

        input[type="radio"]:checked:after {
          transform: scale(1);
          border: 1px solid #427cbc;
          box-shadow: #427cbc;
          transition: 2s transform ease-in-out;
        }

        .form-group {
          margin-bottom: 2px;

          label,
          input {
            font-size: 16px !important;
            color: black;
          }

          input {
            box-shadow: 0.5px 1px 4px grey;
          }
        }

        input[type="text"] {
          width: 100%;
          border-radius: 4px;
          border: 1px solid #fff;
          background: #fff;
          box-shadow: 0.5px 1px 4px grey;
        }

        #dateRate1 {
          background: none;
          border: none;
        }
      }

      .grey-table-row {
        position: relative;
        right: 7px;
      }

      #claimTable {
        .form-group {
          margin-bottom: 2px;

          label,
          input {
            font-size: 16px !important;
            color: black;
          }

          input {
            box-shadow: 0.5px 1px 4px grey;
          }
        }

        input[type="text"] {
          width: 100%;
          border-radius: 4px;
          border: 1px solid #fff;
          background: #fff;
        }

        .form-group {
          select,
          input {
            height: 35px;
            color: #2b2d31;
            font-size: 14px;
            padding-left: 5px;
          }

          label {
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: 11px;
            color: #333;
            margin-bottom: 0;

            &.mandatory::after {
              content: "*";
              padding-left: 2px;
            }
          }

          p.errMsg {
            color: red;
            font-size: 11px;
          }
        }

        input {
          height: 36px;
          margin-bottom: "none";
          color: #2b2d31;
          font-size: 14px;
        }

        color: #919399;
        font-weight: "normal";
        border-collapse: separate;
        border-spacing: 20px 0px;

        .row-no-coverage {
          .no-coverage {
            width: 30%;
            margin-bottom: 20px;

            input {
              height: 12px;
              margin-bottom: 25px;
              margin-right: 5px;
            }
          }
        }
      }
    }

    //styling of review and pay page:
    #payBind {
      input,
      select {
        height: 40px;
      }

      .formTitle {
        margin-bottom: 37px;
      }

      #carrierName {
        font-size: 16px;
      }

      background-image: url("../images/backimage5.png");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 95%;
      background-attachment: sticky;

      .row-formTitle {
        justify-content: space-between;

        .formTitle {
          margin-bottom: 0rem;
        }
      }

      .row-acord-preview-heading {
        justify-content: space-between;

        .col-acord-text {
          margin-left: 16px;
        }

        .col-acord-download {
          margin-left: 15px;
          padding-left: 36px;
        }

        #accordLink {
          color: #0973d5;
        }

        img {
          margin-right: 1rem;
        }
      }

      .row-acord-preview {
        margin-top: 1rem;
        justify-content: center;
        margin-bottom: 3.5rem;

        iframe {
          border: none;
          width: 78%;
          height: 30rem;
        }
      }

      .pdf-upload-placeholder {
        width: 78%;
        height: 30rem;
        margin-top: 2rem;
        justify-content: center;
        align-items: center;
        margin-bottom: 81px;

        .pdf-loader-gif {
          margin-left: 1.5rem;
        }
      }

      .row-tnc {
        margin-top: 1rem;
        font-size: 16px;
        color: #2c2e33;

        p {
          margin-top: 0.6rem;
        }

        .payPageItems2 {
          margin-left: 2rem;
        }

        .linkLike {
          margin-left: 0.5rem;
        }
      }
    }

    //styling for bind page
    #binderForm {
      .linkLike {
        margin-top: 60px;
        margin-bottom: 36px;
      }

      .btnGen {
        margin-bottom: 10px;
      }

      .col-sm-12 {
        .formTitle {
          margin-bottom: 1rem;
        }

        .row-binder-heading {
          padding-right: 10px;
          padding-left: 10px;
          justify-content: space-between;
        }

        .row-iframe {
          justify-content: center;
          width: 100%;

          .col-iframe {
            padding-left: 25px;
            padding-right: 25px;
            width: 100%;

            .acord {
              justify-content: flex-end;
              width: 95%;
              color: #0973d5;

              span {
                position: relative;
                top: 4px;
                margin-left: 0.5rem;
              }
            }

            .row-iframe-preview {
              width: 100%;
              justify-content: center;

              iframe {
                // margin-top: 2rem;
                border: none;
                width: 90%;
                height: 45rem;
              }
            }
          }
        }
      }
    }

    .class-desc-select {
      background-color: white;

      div {
        height: 35px;
        padding-top: 0;
        padding-bottom: 0;
      }

      .class-desc-select__indicator {
        align-items: center;
      }

      .class-desc-select__menu {
        height: fit-content;
        width: fit-content;
        max-width: 350px;
        min-width: -webkit-fill-available;
        min-width: -moz-available;

        .class-desc-select__menu-list {
          height: fit-content;
          max-height: 150px;

          .class-desc-select__option {
            display: flex;
            align-items: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: fit-content;
          }
        }
      }

      .class-desc-select__placeholder {
        margin: 0;
        position: unset;
        transform: none;
        height: fit-content;
      }

      .class-desc-select__control {
        // min-height: 35px;
        // border: 1px solid #ababab;
        // background: transparent;
        // border-radius: 4px;
        .class-desc-select__value-container {
          overflow: initial;

          .class-desc-select__single-value {
            height: fit-content;
          }
        }
      }
    }

    .locationName {
      span {
        font-size: 16px;
        font-weight: normal;
        color: #2b2d31;
      }
    }

    #extraInput {
      margin-top: 5px;
    }

    .check {
      margin-right: 0.3rem;
    }

    #newAddress {
      display: none;
      margin-left: 0;
    }

    .payPageItems {
      margin-left: 1rem;
    }

    .linkLike {
      position: relative;
      right: 0.55rem;
      // margin-top: 68px;
      // margin-bottom: 63px;
    }

    // // styl for binder
    .green {
      margin-left: 25%;
      font-size: 22px;
      font-weight: 600;
      color: #138f11;
      margin-bottom: 16px;

      p {
        margin: 0;
      }
    }

    .endForm {
      margin-left: 2rem;
    }
  }

  //styling for review and pay
  //paybind is the main container of review and pay page:
  // styles of side tab
  .newquote-sidebar {
    width: 19.5%;
    background-color: #f8f9fa;
    padding: 10px 5px 0px 5px;
    border-right: 1px solid #dee2e6;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #e9ecef;
    }

    .sidebar-heading {
      font-size: 12px;
      font-weight: bold;
      color: #080808;
      margin-bottom: 1rem;
      text-align: left;

      span {
        font-size: 12px;
        color: #080808;
      }
    }

    .nav-link {
      font-size: 12px;
      padding: 10px 20px;
      border-radius: 5px;
      transition: background-color 0.3s ease, color 0.3s ease;
      display: flex;
      align-items: center;
      margin: 10px;
      color: black;

      img {
        margin-right: 10px;
        border-radius: 10%;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.1);
        }
      }

      &.active {
        background-color: #427cbc;
        color: #fff;
      }

      &.disabled {
        color: #adb5bd;
        pointer-events: none;
      }

      &:hover:not(.active) {
        background-color: #e2e6ea;
      }
    }

    .sidebar-stagename {
      font-weight: bolder;
      // color: #ffc423;
      margin-right: 5px;
      font-size: 13px;
    }

    .tick {
      width: 25px;
      height: 25px;

      &.disabled {
        display: none;
      }
    }

    .m-menu__toggle {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
    }

    .m-menu__overlay {
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 0.3s ease;
      pointer-events: none;

      &.visible {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

.zonefirst {
  margin-top: 20px;
  display: flex;
  //flex-wrap: wrap; /* Allow flex items to wrap to the next line */
  // justify-content: space-between;
  // width: 100%;
  padding: 20px;
}

.contact-form-client {
  padding-top: 60px;
  width: 32%;
  // min-width: 32%;
}

.headers-companies {
  padding-top: 90px;
  // width: 35%;
  // min-width: 35%;
  height: 500px;
  //margin-bottom: 50px;
  //position: relative;
  //margin-right: -50px;
}

.header-sompany-logo-style {
  width: 80%;
  margin: auto;
}

.header {
  font-size: xx-large;
}

.image-background-banner {
  background-color: white;
  padding: 10px;
  padding-bottom: 20px;
}

.bannerStampText {
  width: 100%;
  padding: 14px 10px 10px 17px;
  background-color: #f3ca52;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  font-weight: bold;
}

.image-and-logo .bannerStamps {
  width: 100%;
}

.image-banner-style {
  //width: 36%;
  z-index: 10;
}

.banner-two-people-image {
  width: 100%;
}

@media only screen and (max-width: 1350px) {
  .newquote-sidebar {
    font-size: 0.8rem !important;
  }

  #undrQues {
    font-size: 1rem !important;
  }

  #cmprQts {
    font-size: 0.8rem !important;
  }

  #compareQuote {
    font-size: 0.8rem !important;
  }
}

@media only screen and (max-width: 992px) {
  .newquote-sidebar {
    padding-left: 0.2rem;
    background-color: #ffffff;
    margin-top: 0rem !important;
    padding-bottom: 0rem !important;
    margin-right: 0rem !important;
  }
}

@media only screen and (max-width: 750px) {
  .newquote-content {
    font-size: 0.8rem !important;
  }
}

#downloadable {
  width: 90%;
  margin: auto;
  padding-top: 20px;
  font-size: 14px;

  strong {
    font-size: 16px;
  }
}

#downloadable {
  .sectionHolder {
    padding: 20px;
    margin: 30px auto;
  }
}

.home-page {
  //styling of the new home page
  padding-top: 5rem !important;

  #banMain {
    img {
      width: 100%;
    }

    h2 {
      // font-family: "Merriweather", sans-serif;
      color: #2162ac;
      font-size: 50px;
      font-weight: 600;
      margin-top: 26px;
      margin-bottom: 16px;
    }

    h3 {
      color: #2f3237;
      font-size: 20px;
      line-height: 1.5;
      font-weight: normal;
      margin-bottom: 36px;
    }

    .btn {
      color: white;
      // font-family: "Rubik", sans-serif;
      font-size: 22px;
      font-weight: normal;
      border-radius: 7.2px;
      color: #fff;
      background-color: #ffd13f;
      border-color: #eea236;
      margin-bottom: 30px;
      box-shadow: 2px 4px 12px #eea236;
    }
  }
}

#itWorks {
  margin-right: 0px;
  margin-left: 0px;

  .row-3steps {
    // width: 100%;
    padding: 40px 0 40px 0;
    height: auto;
    background-color: #2162ac;

    // background-color: #2e3952;
    //3 steps logo
    .col-simplesteps {
      color: white;
      text-align: left;

      .simple-steps-wrapper {
        max-width: 300px;
      }

      h2 {
        text-align: left;
        font-size: 200px;
        margin-bottom: 0px;
        line-height: 1;
      }

      h3 {
        font-size: 44px;
        font-weight: 300;
        text-align: left;
      }

      img {
        margin: 1rem;
        position: relative;
        left: 15px;
        display: none;
      }

      .line-below-3 {
        width: 180px;
        height: 4px;
        opacity: 0.5;
        background-color: #ffffff;
        margin-bottom: 1rem;
        margin-top: 10px;
      }
    }

    //3-steps cards
    .col-simplesteps-cards {
      .card {
        width: 90%;
        color: white;
        background-color: transparent;

        .row {
          align-items: center;

          .col-card-image {
            height: auto;
            margin-right: 10px;

            .card-img {
              border: 0px !important;
              width: 97px;
              object-fit: contain;
              // img {
              //   margin-bottom: 35px;
              // }
            }
          }

          .col-card-text {
            height: auto;

            .card-body {
              text-align: left;

              .card-title {
                // font-family: "Rubik", sans-serif;
                font-size: 28px;
                font-weight: 500;
              }

              .card-text {
                margin-top: auto;
                // font-family: "Rubik", sans-serif;
                font-size: 20px;
                line-height: 1.5;
                font-weight: normal;
              }

              .learn-more {
                // font-family: Arial, Helvetica, sans-serif;
                color: #2ed359;
                font-size: 16px;
                font-weight: bold;
              }

              .learn-more-arrow {
                // font-size: 2rem;
                font-weight: bolder;
              }
            }
          }
        }
      }
    }
  }

  .row-our-mission {
    color: white;
    height: 420px;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.4),
      rgba(0, 153, 255, 0.4) 100%
    );
    background-image: url("../images/home-mission-background.jpg");

    .col-md-12 {
      padding: 80px 80px 80px 120px;

      h1 {
        position: relative;
        right: 13px;
        // font-family: "Merriweather", sans-serif;
        font-size: 100px;
        font-weight: bold;
        margin-bottom: 15px;
      }

      h2 {
        // font-family: "Rubik", sans-serif;
        font-size: 24px;
        font-weight: normal;
        line-height: 1.5;
      }
    }
  }

  .row-everything-you-need {
    .col-md-12 {
      padding: 115px;

      h1 {
        color: #353a3f;
        // font-family: "Merriweather", sans-serif;
        font-weight: bold;
        font-size: 44px;
      }

      .card-row {
        .card {
          background-color: #f8f9fb;
          height: auto;
          margin: 20px;
          text-align: center;

          img {
            height: 80px;
            width: auto;
            margin-top: 40px;
            margin-bottom: 30px;
          }

          .card-title {
            color: #33333c;
            // font-family: "Rubik", sans-serif;
            font-size: 20px;
            line-height: 1.3;
            margin-bottom: 2rem;
          }
        }
      }
    }
  }

  .row-video {
    background-color: #e7f4ff;
    height: auto;
    justify-content: center;

    .col-md-12 {
      padding-left: 114px;
      padding-right: 114px;
      padding-top: 100px;

      h1 {
        color: #2c2e34;
        // font-family: "Merriweather", sans-serif;
        font-size: 44px;
        font-weight: bold;
        line-height: 1.27;
      }

      h2 {
        color: #2c2e34;
        font-weight: normal;
        font-size: 20px;
        line-height: 1.5;
        margin-bottom: 40px;
      }

      iframe {
        margin-bottom: 120px;
      }
    }
  }
}

#footerMain {
  // font-family: "Rubik", sans-serif;
  padding-top: 24px;
  padding-bottom: 20px;
  color: white;
  height: 4.75rem;
  // background-color: #2e3952;
  background-color: #183650;
  padding-left: 100px;
  padding-right: 100px;

  .row-1 {
    div:nth-of-type(6) {
      margin-right: 10px;
    }

    justify-content: space-between;

    ul {
      padding-left: 2px;
      list-style: none;
      font-size: 16px;
    }

    .col-img {
      img {
        height: 75px;
        width: 130px;
      }
    }

    .col {
      a {
        color: white;

        &:hover {
          text-decoration: none;
          color: white;
        }
      }
    }
  }

  .t-and-c-row {
    // opacity: 0.6;
    font-size: 13px;
    line-height: 2.23;

    .col-sm-2 {
      a {
        color: white;
      }
    }

    .dull {
      opacity: 0.6;
    }

    .back-to-top {
      margin-right: 10px;
    }
  }
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0%;
    // transform: translateY(-10%);
  }

  100% {
    opacity: 100%;
    // transform: translateY(0);
  }
}

.specialAdminRow {
  td {
    vertical-align: middle !important;
  }
}

.quote-table {
  .headingboxstyle {
    justify-content: space-between;
    margin-top: 40px;
    color: black;
    margin-bottom: 50px;
    font-size: 20px;
    font-weight: bold;
    display: flex;

    .font-family-montserrat-bold {
      text-align: left;
      width: 30%;
      font: bold;
      padding-left: 100px;
      padding-top: 5px;
      padding-bottom: 5px;
      background-color: #ffc423;
    }

    .companyProfileheadertwo {
      width: 69%;
      background-color: #ffdf87;
    }
  }

  .paygo-eligibility-container {
    margin-inline: 2rem;
    font-size: 14px;
  }

  .paygo-and-peo-container {
    // transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 87%;
    margin: auto;

    animation: 1s ease-out 0s 1 slideInFromLeft;
  }

  .bindquotebtnstyle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 130px;

    .bindquotebtnstyletwo {
      display: flex;
      width: 65%;
      justify-content: space-between;

      .bindquotebtn {
        width: 48%;
        height: 110px;
        margin: auto;
        border: 2px solid transparent;
        border-image: linear-gradient(to right, #13a0f9, #faa541);
        border-image-slice: 1;
        padding: 5px;
        display: flex;
        justify-content: center;
        border-radius: 1em;
        font-size: 27px;

        button {
          width: 100%;
          border-image: linear-gradient(to right, #13a0f9, #faa541);
          background-color: red;
          /* For browsers that do not support gradients */
          background-image: linear-gradient(to right, #13a0f9, #faa541);
        }
      }

      .bindquotebtn-disable {
        width: 48%;
        height: 110px;
        margin: auto;
        border: 2px solid transparent;
        border-image: linear-gradient(to right, #e3e3e3, #a3a2a0);
        border-image-slice: 1;
        padding: 5px;
        display: flex;
        justify-content: center;
        border-radius: 1em;
        font-size: 27px;

        button {
          width: 100%;
          //border-image: linear-gradient(to right, #e3e3e3, #a3a2a0);
          background-color: #a3a2a0;
          /* For browsers that do not support gradients */
          // background-image: linear-gradient(to right, #e3e3e3, #a3a2a0);
        }
      }
    }
  }

  .head-row {
    align-items: center;
    justify-content: space-between;
  }

  .hr1 {
    color: #2c2e33;
    font-size: 26px;
    font-weight: 500;

    &.quote-table-company-name {
      // font-family: "Overpass", sans-serif;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .hr2 {
    color: #2c2e33;
    width: 70%;
    margin-top: 1rem;
    margin-bottom: 1rem;

    span {
      color: #5f636b;
    }
  }

  .hr3 {
    margin-bottom: 1.5rem;
    color: #2c2e33;
  }

  .body-row {
    margin-top: 2rem;
    justify-content: center;

    .table {
      margin-bottom: 7rem;
      position: relative;
      right: 1rem;
      border-collapse: separate;
      border-spacing: 25px 0px;

      thead {
        th {
          color: #2c2e33;
          position: relative;
          right: 0.6rem;
          font-size: 16px;
        }
      }

      tbody {
        font-size: 18px;

        td,
        th {
          width: 25%;
          border: 1px solid white;
          background-color: white;
          color: #2c2e33;
          font-size: 18px;
          font-weight: normal;
        }

        td {
          p {
            font-size: 22px;
            font-weight: 800;
            color: #1463ac;

            .best {
              font-size: 14px;
              margin-left: 1em;
              color: #038b00;
            }

            .higher {
              font-size: 14px;
              margin-left: 1rem;
              color: #2c2e33;
            }
          }

          input {
            margin: 0.5rem;
          }
        }
      }
    }
  }

  .admin_type_value {
    flex-basis: content;
  }
}

#resDrop,
#workDrop,
#quotesDrop,
#profDrop {
  position: relative;
  display: none;
  padding: 0;
}

#resDrop .navDrop,
#quotesDrop .navDrop,
#workDrop .navDrop,
#profDrop .navDrop {
  background: #ffffff;
  position: absolute;
  z-index: 1;
  text-align: left;
  width: 180px;
  top: 10px;
  border-radius: 6px;
  box-shadow: 5px 5px 20px #888888;
}

#quotesDrop .navDrop {
  right: 0px;
  padding: 10px;
}

#profDrop .navDrop {
  width: 200px;
  right: 0px;
}

#profDrop hr {
  margin-top: 0;
  margin-bottom: 0;
}

#profDrop .navDrop li {
  display: inline-block;
  padding: 15px;
  color: #095d98;
  font-size: 1.5rem;
  font-style: initial;
}

#resDrop .navDrop li,
#quotesDrop .navDrop li,
#workDrop .navDrop li {
  display: block;
  color: #095d98;
  padding: 10px;
}

#Dropresources:hover #resDrop,
#Dropworks:hover #workDrop,
#Dropquotes:hover #quotesDrop,
#Dropprofile:hover #profDrop {
  display: block;
}

#Dropprofile:hover #profDrop {
  display: block;
}

#resDrop .navDrop img,
#workDrop .navDrop img {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.tab-content {
  // height: 600px;
  min-height: calc(100vh - 9rem);
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tab-content-mobile {
  min-height: fit-content;
}

// .tab-content-login,
// .tab-content-compliance {
//   min-height: calc(100vh - 15.75rem);
// }
// .tab-content-quote {
//   min-height: calc(100vh - 9rem);
// }
#loginButton {
  margin-left: 1rem;
  margin-right: 1rem;
}

#formLogin {
  height: auto;
  width: 450px;
  background: #ffffff;
  margin: auto;
  position: relative;
  padding-top: 20px;
  transition: all 0.5s;
}

#formChange {
  width: 450px;
  background: #ffffff;
  margin: auto;
  position: relative;
  padding-top: 20px;
  transition: all 0.5s;
}

#formReset {
  // min-height: 390px;
  width: 450px;
  background: #ffffff;
  margin: auto;
  position: relative;
  padding-top: 20px;
  transition: all 0.5s;
}

#formReset,
#formLogin,
#formChange {
  border: 1px solid #f9f9f9;
}

#formReset:hover,
#formLogin:hover,
#formChange:hover {
  box-shadow: 0 10px 15px #888888;
  transition: all 0.5s;
}

#lgin {
  /* padding: 150px 0; */
  transition: all 0.5s;
  margin: auto;
}

#chngpwd,
#fgtpwd {
  margin: auto;
}

.forSmall {
  display: none;
}

#loader {
  position: fixed;
  background: rgba(255, 255, 255, 0.9);
  height: 100%;
  width: 100%;
  z-index: 9;
  top: 0;
  text-align: center;
  display: none;
}

#loader img {
  position: relative;
  width: 80px;
  top: 40%;
  margin: auto;
}

#formReset,
#formChange,
#formLogin {
  margin-bottom: 16px;

  .formTitle {
    color: white;
    font-size: 20px;
  }

  padding-top: 40px;
  border-radius: 10px;

  background-color: #427cbc;

  form {
    padding: 0 20px;
  }

  form input[type="password"],
  form input[type="email"],
  form input[type="text"] {
    width: 100%;
    font-size: 0.85em;
    border-radius: 4px;
    border: 1px solid white;
    background: white;
    color: #2b2d31;
    height: 35px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .btn-center {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
  }

  button {
    height: 35px;
    width: 60%;
    margin: auto;
    border-radius: 5px;
    background-image: linear-gradient(to right, #ffc423, #ffc423);
    border-color: transparent;
    color: white;
    font-size: 14px;
    font-weight: 800;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .formBottomLinks {
    justify-content: space-between;

    a {
      color: white;
    }
  }

  .inptSideText {
    color: white;
  }
}

.formBottomLinks {
  padding: 20px;
}

#changePassForm {
  input[type="text"] {
    width: 100%;
    font-size: 0.85em;
    border-radius: 4px;
    border: 1px solid white;
    background: white;
    color: #2b2d31;
    font-weight: "normal";
    height: 35px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

#quoteMain {
  @include cmprQts;
  background-color: #e2f2ff;

  #contentHolder {
    table {
      position: relative;
      right: 32px;
    }
  }
}

#resDrop,
#workDrop {
  .navDrop {
    padding: 0px;
    margin-left: -4rem;
    margin-top: 3rem;

    li {
      color: #0068c9;
      font-size: 13px;

      img {
        margin-left: 0px;
        margin-right: 8px;
        padding-left: 0px;
      }
    }

    a:hover {
      text-decoration: none;
    }
  }
}

.cmprQts-buttons {
  margin-bottom: 10px;
  text-align: center !important;
  // margin-left: 14rem;
}

.btnGen {
  margin-bottom: 36px;
}

#uwPortal {
  .fixedHeader {
    thead > tr > th {
      position: sticky;
      top: -1px;
      z-index: 3;
      background-color: white;
    }

    tbody > tr > td {
      vertical-align: middle;
    }
  }

  display: flex;
  height: calc(100vh - 5.7rem);
  // overflow-x: hidden;
  // overflow-y: auto;
  width: 100%;

  .btn-sm {
    min-width: 60px;
    margin-bottom: 5px;
  }

  .custom-control-label::before {
    color: #fff;
    background-color: #e97d88;
    border-color: #e97d88;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #28a745;
    background-color: #28a745;
  }

  .custom-switch .custom-control-label::after {
    background-color: #fff;
  }
}

.modal-title-centered {
  background-color: #ffdf87;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.custom-modal-body {
  background-color: #ffdf87;
}

.modal-full {
  width: 95%;
  max-width: none !important;
  height: 95%;

  iframe {
    min-height: 80vh;
  }
}

@media only screen and (max-width: 740px) {
  .new-navbar {
    .boxed-logo {
      height: 100px;
      padding: 15px;
    }
  }
}

@media only screen and (max-width: 580px) {
  .new-navbar {
    .boxed-logo {
      height: 75px;
      padding: 11px;
      margin-top: 30px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .new-navbar {
    .brand-container {
      width: 70%;
    }

    .boxed-logo {
      height: 60px !important;
      padding: 8px;
      margin-top: 35px;
    }
  }

  #compProfile {
    .company-profile-container {
      margin-left: 10px;
      margin-right: 20px;
    }
  }
}

@media only screen and (max-width: 420px) {
  .new-navbar {
    .boxed-logo {
      margin: 5px 10px;
      margin-left: 20px;
      margin-top: 35px;
    }
  }
}

@media only screen and (max-width: 380px) {
  .new-navbar {
    .pride-logo {
      margin-left: 1.5rem;
    }

    .insurecomp-logo {
      margin-right: 1rem;
    }

    .new-logo {
      height: 3rem;
    }
  }
}

@media only screen and (max-width: 360px) {
  .new-navbar {
    .boxed-logo {
      height: 50px !important;
      padding: 5px;
      margin: 5px 5px;
      margin-top: 40px;
      margin-left: 15px;
    }
  }
}

@media only screen and (max-width: 340px) {
  .new-navbar {
    .pride-logo {
      margin-left: 1.5rem;
    }

    .insurecomp-logo {
      margin-right: 1rem;
    }

    .new-logo {
      height: 2.5rem;
    }
  }
}

.dashboard-admin {
  .new-navbar {
    background-color: white;
    border-bottom-style: groove;
  }

  .tabs {
    .MuiTableSortLabel-root.MuiTableSortLabel-active {
      color: white;

      .MuiTableSortLabel-icon {
        color: white !important;
      }
    }

    .nav-item {
      a,
      .active {
        color: black;
      }

      .active {
        background-color: #faa558;
      }
    }
  }
}

.dropZonesContainer {
  color: #2b2b2be2;
  //   justify-content: center;

  //   font-size: 2\10px;
  //   display: flex;
  width: 100%;

  //   margin: auto;
  //   margin-top: 100px;
  //   // padding: 0 25px;
  //   .customrow {
  //     //  border: 2px solid black;
  //     justify-content: space-around;
  //     margin-right: 154px;
  //     margin-left: 47px;
  //   }
  //   .customrow1 {
  //     margin-right: 321px;
  //     margin-left: 70px;
  //   }
  .customDropZone,
  .customDropZone-uw {
    // height: 100px;
    width: 500px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: flex-end;

    padding-inline: 5px;

    .customdropcontent {
      font-size: 15px;
    }

    .dropzone {
      text-align: center;
      width: 100%;
      height: 40px;
      padding-top: 2px;
      border: 2px solid #faa558;
      background-color: #fafafa;
      border-radius: 10px;
      color: #8a5656;

      .dropZoneText {
        margin-top: 7px;
        cursor: pointer;
        padding-left: 40px;
        padding-right: 40px;
      }
    }

    //     li {
    //       background: none;
    //       .fw-bold {
    //         cursor: pointer;
    //       }
    //     }
  }

  .customDropZone-dashborad {
    // height: 100px;
    // width: 373px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;

    padding-inline: 5px;

    .customdropcontent {
      font-size: 15px;
    }

    .dropzone {
      text-align: center;
      width: 100%;
      height: 40px;
      padding-top: 2px;
      border: 2px solid #faa558;
      background-color: #fafafa;
      border-radius: 10px;
      color: #8a5656;

      .dropZoneText {
        margin-top: 7px;
        cursor: pointer;
        padding-left: 40px;
        padding-right: 40px;
      }
    }
  }

  .customDropZone-uw {
    //height: 285px !important;
    margin-top: 50px;
    display: flex;
    align-items: center;

    .dropzone {
      height: 100px !important;
    }
  }

  .customDropZone-dashborad {
    height: 130px !important;
    display: flex;
    align-items: center;

    .dropzone {
      height: 100px !important;
    }
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-popup-container {
  background-color: #ffdf87;
  border-radius: 15px;
  box-shadow: 2px 4px 12px black;
  position: absolute;
  // height: 150px;
  width: 400px;
  left: 50%;
  top: 50%;
  padding: 20px;
  color: black;
  font-weight: bold;
  transform: translate(-50%, -50%);
  text-align: center;

  .header-text {
    font-weight: bold;
    line-height: 35px;
  }

  .btn.upload-docs-action {
    background-color: #427cbc;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 5px;
    transition: background-color 0.3s ease;
  }

  .btn.upload-docs-action:hover {
    background-color: #0056b3;
  }
}

.popup-container {
  background-color: #ffdf87;
  border-radius: 15px;
  box-shadow: 2px 4px 12px black;
  position: absolute;
  height: auto;
  width: 500px;
  left: 50%;
  top: 50%;
  padding: 20px;
  color: black;
  font-weight: bold;
  transform: translate(-50%, -50%);

  .header-text {
    font-weight: bold;
    line-height: 35px;
  }
}

.tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  text-transform: uppercase;
}

.tab-list-active {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

.class-desc-select__menu {
  width: 60vw !important;
  max-width: none !important;
}

.class-desc-select__option {
  width: auto !important;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.external-box {
  width: 30%;
  margin: auto;
  .no-login-user-heading-check {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .check-company-info {
      text-align: left;
      color: #427cbc;
      font-size: 20px;
      font-weight: bolder;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.pink-header {
  justify-content: space-between;
  margin-top: 20px;
  color: black;
  margin-bottom: 50px;
  font-size: 20px;
  font-weight: bold;
  display: flex;

  .font-family-montserrat-bold {
    text-align: left;
    width: 33%;
    font-size: 20px;
    font: bold;
    padding-left: 100px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #ffc423;
  }

  .companyProfileheadertwo {
    width: 66%;
    background-color: #ffdf87;
  }
}

.pink-header1 {
  justify-content: space-between;
  margin-top: 40px;
  color: black;
  margin-bottom: 50px;
  font-size: 20px;
  font-weight: bold;
  display: flex;

  .font-family-montserrat-bold {
    text-align: left;
    width: 33%;
    font-size: 20px;
    font: bold;
    padding-left: 60px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #ffc423;
  }

  .companyProfileheadertwo {
    width: 66%;
    background-color: #ffdf87;
  }
}

#company-name-link:hover {
  text-decoration: underline;
}

.home-login {
  width: 100%;
  height: 60px;
  padding: 3px;
  background-color: #ffc423;
  display: flex;
  justify-content: space-around;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  z-index: 1000;

  .menustyle {
    justify-content: center;
    align-items: center;

    a {
      text-decoration: none;
      color: white;
    }

    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px 15px;
    background-color: #427cbc;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
    gap: 5px;

    .logostyle {
      padding-bottom: 2px;
    }
  }

  .menustyle:hover {
    background-color: #2c537c;
  }

  .user-dropdown {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    background-color: #427cbc;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
    gap: 5px;

    &.dropdown-toggle {
      cursor: pointer;
    }
  }

  .user-dropdown:hover {
    background-color: #2c537c;
  }

  .logoutstyle {
    a {
      color: "black";
      font-weight: 550;
      font-size: 20px;
      font-weight: 550;
      cursor: pointer;
    }
  }
}

.dropdown-menu.show {
  background-color: #fff5dc;
}

.dropdown-item:hover {
  background-color: #ffdb73;
}

.home-landing-page {
  background-color: white;
  overflow-y: hidden;

  .btn {
    background-color: #f8af16;
  }

  // @media only screen and (min-width: 2000px) {
  //   .why-us {
  //     .row-2 {
  //       .row {
  //         padding-top: 120px !important;
  //         .col {
  //           .custom-card {
  //             height: 300px !important;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  // @media only screen and (min-width: 1700px) {
  //   .why-us {
  //     .row-2 {
  //       .row {
  //         padding-top: 100px !important;
  //         .col {
  //           padding-top: 30px !important;
  //           padding-bottom: 30px !important;
  //           .custom-card {
  //             height: 250px !important;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  @media only screen and (min-width: 1600px) {
    .testimonial {
      height: 1000px !important;

      .testimonial-text-container {
        font-size: 30px !important;
      }
    }

    .learn-more-about-us {
      font-size: 30px !important;

      button {
        font-size: 20px !important;
      }
    }

    .custom-card-text {
      font-size: 20px !important;
    }
  }

  @media only screen and (min-width: 1400px) {
    .why-us {
      .row {
        padding-top: 40px !important;
      }

      .row-2 {
        .custom-card {
          height: 17vh !important;
        }
      }
    }
  }

  @media only screen and (min-width: 1750px) {
    .why-us {
      .row-2 {
        height: 0 !important;
        padding: 0 !important;
        padding-bottom: 8% !important;
        height: max-content !important;
        background-repeat: no-repeat !important;
        // background-attachment: fixed;
        background-size: cover !important;
        background-position: center center !important;
      }
    }
  }

  @media only screen and (min-width: 1380px) {
    .why-us {
      .row-2 {
        height: 0 !important;
        padding: 0 !important;
        padding-bottom: 10% !important;
        height: max-content !important;
        background-repeat: no-repeat !important;
        // background-attachment: fixed;
        background-size: cover !important;
        background-position: center center !important;
      }
    }
  }

  @media only screen and (max-width: 1195px) {
    .custom-card-text {
      font-size: 13px !important;
    }
  }

  @media only screen and (max-width: 1100px) {
    .custom-card-image {
      padding-left: 15px !important;
    }

    .top-image {
      // height: 700px !important;
      min-height: auto;
      padding: none;
    }

    // .top-image {
    //   height: auto !important;
    //   min-height: auto;
    //   padding: none;
    // }
    .logo-2 {
      width: 285px !important;
    }
  }

  @media only screen and (max-width: 992px) {
    .top-image {
      // height: 975px !important;
      min-height: auto;
      padding: none;
    }
  }

  @media only screen and (max-width: 900px) {
    .play-button {
      height: 70px !important;
      width: 70px !important;
    }

    .vid-img-cover {
      margin-top: -69% !important;
    }

    .why-us {
      .row-2 {
        height: fit-content;
        background-image: none !important;
        background-color: #7f3e99 !important;
      }
    }
  }

  @media screen and (max-width: 520px) {
    .ready-for-whatever-comes-next {
      margin-top: 30px !important;
    }
  }

  @media only screen and (max-width: 510px) {
    .logo-1 {
      img {
        height: 272px !important;
      }
    }

    .logo-2 {
      img {
        height: 125px !important;
        margin-top: 20px;
      }
    }

    .play-button {
      height: 50px !important;
      width: 50px !important;
      margin-top: -66% !important;
    }

    .custom-card-image {
      padding-right: 15px !important;
    }

    .ready-for-whatever-comes-next {
      height: auto !important;
      font-size: 33px !important;
    }
  }

  @media only screen and (max-width: 1250px) {
    .ready-for-whatever-comes-next {
      font-size: 2.5rem !important;
    }
  }

  @media only screen and (max-width: 1000px) {
    .ready-for-whatever-comes-next {
      font-size: 2.1rem !important;
    }
  }

  .aquired-by {
    margin-top: 16px;
    margin-left: 62px;
    width: 40vw;
    height: 52px;
    /* UI Properties */
    text-align: left;
    // font: "Open Sans";
    letter-spacing: 0px;
    font-size: 1.3rem;
    color: #ffffff;
    opacity: 1;
    line-height: 20px;
    font-family: "MontserratRegular" !important;
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 1400px) {
    .aquired-by {
      font-size: 1rem !important;
      margin-top: 10px !important;
      margin-left: 52px !important;
    }
  }

  @media only screen and (max-width: 1250px) {
    .aquired-by {
      font-size: 0.9rem !important;
      margin-top: -5px !important;
      margin-left: 54px !important;
    }
  }

  @media only screen and (max-width: 900px) {
    .aquired-by {
      font-size: 0.9rem !important;
      margin-top: 30px !important;
      margin-left: 54px !important;
    }
  }

  @media only screen and (max-width: 700px) {
    .aquired-by {
      font-size: 0.8rem !important;
      margin-top: 30px !important;
      margin-left: 54px !important;
    }
  }

  @media only screen and (max-width: 450px) {
    .aquired-by {
      margin-left: auto !important;
      margin-right: auto !important;
      width: 90% !important;
      word-break: break-word !important;
      font-size: 15px !important;
    }

    .custom-card-image {
      padding-right: 5px !important;
    }

    .ready-for-whatever-comes-next {
      height: auto !important;
      font-size: 30px !important;
      margin-left: 20px !important;
    }

    .vid-img-cover {
      margin-top: -73% !important;
    }

    .learn-more-about-us {
      .form-col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media only screen and (max-width: 470px) {
    .zonefirst {
      .image-banner-style {
        margin-top: 55px;
      }
    }

    .custom-card-image {
      padding-left: 5px !important;
    }
  }

  @media only screen and (max-width: 480px) {
    .fixed-ball {
      display: none;
    }
  }

  @media only screen and (max-width: 420px) {
    .ready-for-whatever-comes-next {
      height: auto !important;
      font-size: 25px !important;
      word-break: break-word !important;
      width: 90% !important;
    }

    .btn {
      text-align: center;
      // margin-left: 50% !important;
      transform: translate(-17%, 0);
    }
  }

  .custom-card {
    width: 90%;
    height: 20vh;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;

    .row-custom-card {
      height: 78px;
      display: flex;
      width: 100%;

      // padding-left: 55px;
      // padding-right: 55px;
      .custom-card-image {
        padding-left: 50px;
        // width: 72px;
        padding-right: 44px;
      }

      .custom-card-text {
        font-size: 1.2rem;
        // width: 384px;
        word-break: break-word;
        font-family: "MontserratMedium" !important;
      }
    }
  }

  .row-1 {
    height: 90px;
    margin-bottom: 84px;

    .horizontal-continer {
      width: 100%;

      hr {
        width: 50px;
        height: 0px;
        border: 3px solid #ff9900;
        opacity: 1;
      }
    }

    .our-services-text,
    .why-us-text,
    .learn-text {
      font-family: "MontserratMedium" !important;
      width: 100%;
      height: 48px;
      text-align: center;
      font-size: 34px;
      letter-spacing: 0px;
      color: #844c94;
      opacity: 1;
    }
  }

  .top-image {
    margin-top: -80px;
    background-image: url(../images/engageHeaderLogosBG.png) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    width: 100%;

    .whiteBackgroundbox {
      position: absolute;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
      align-items: center;
      justify-content: center;
      width: 100%;
      /* Set the width of your box */
      height: 250px;
      /* Set the height of your box */
      background-color: white;
    }

    .first-row {
      padding-top: 15px;
    }

    .ready-for-whatever-comes-next {
      font-family: "MontserratMedium" !important;
      margin-top: 90px;
      margin-left: 50px;
      width: 45vw;
      height: 120px;
      text-align: left;
      font-size: 3.1rem;
      letter-spacing: 0px;
      color: #ffffff;
      line-height: initial;
      opacity: 1;
      word-break: break-word;
    }

    @media only screen and (min-width: 1800px) {
      .ready-for-whatever-comes-next {
        font-size: 3.5rem !important;
        margin-top: 130px !important;
        margin-left: 60px !important;
      }
    }

    .btn {
      margin-top: 1rem;
      width: 207px;
      height: 40px;
      margin-left: 50px;
      color: white;
    }
  }

  .home-banner {
    .banner-container {
      min-width: 100%;
      height: auto;
      background-image: url(../images/Artboard.png);
      background-repeat: no-repeat !important;
      background-size: cover !important;
      display: flex;
      align-items: center;
      position: relative;

      // .whitebox-banner {
      //   height: 200px;
      //   min-width: 100%;
      // }
      .white-box {
        position: absolute;
        background: white;
        z-index: 0;
        top: 321px;
        height: 212px;
        width: 100%;
      }
    }

    .banner-content-container {
      display: flex;
      width: 100%;
      height: auto;
      margin: auto;
      position: absolute;
      top: 22rem;

      .banner-images-container {
        border: 2px solid red;
        display: flex;

        .banner-text-box {
          padding-top: 13%;
          margin-left: 100px;
          border: 1px solid black;
        }

        .banner-people-image-box {
          .banner-two-people-image {
            width: 100%;
          }
        }

        .banner-text-box {
          width: 43%;
          margin-top: 20px;
        }

        .banner-stamps-text-container {
          padding-right: 1rem;

          .bannerStamps {
            max-width: 90%;
            width: 90%;
          }

          .bannerStampText {
            width: 100%;
            padding: 14px 10px 10px 17px;
            background-color: #f3ca52;
            margin-bottom: 10px;
            text-align: left;
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
            font-weight: bold;
          }
        }

        .banner-people-image-box {
          // .banner-two-people-image {
          //   height: 155%;
          //   width: 64%;
          //   margin-top: -10rem;
          //   position: absolute;
          //   right: 0px;
          // }
        }
      }
    }

    .et_pb_bottom_inside_divider {
      display: block;
      height: 100%;
      position: absolute;
      pointer-events: none;
      top: 58rem;
      width: 100%;
      left: 0;
      right: 0;
      background-color: #ffc94a;
      bottom: 0;
      height: 24px;
      z-index: 2;
      transform: scale(-1, 1);
    }
  }

  .our-services {
    // margin-top: 50px;
    top: 0px;
    left: 0px;
    opacity: 1;
    max-width: 100% !important;
    padding: 0;
    position: relative;
    background-position: left top;
    background-image: url(../images/ArtboardOP2.png) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;

    .why-engage-section {
      width: 100%;

      .why-engage-container {
        height: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .why-engage-container-box {
          width: 80%;
          // border: "1px solid red",
          height: auto;
          display: flex;
          align-items: center;
          position: relative;
        }

        .why-engageImagebox {
          width: 100%;
          height: 60%;
          background-color: #ffdb5c;
          border-radius: 200px 0 0 200px;
          position: absolute;
          /* Position why-engageImagebox absolutely inside why-engage-container */
          bottom: 0;
          /* Align to the bottom of the container */
          left: 0;
          /* Align to the left of the container */
          z-index: 1;
          display: flex;
          justify-content: flex-end;

          .cardsContainer {
            width: 60%;
            height: 100%;
            border: 1 px solid green;

            display: grid;
            grid-template-columns: repeat(2, 1fr);
            /* 2 columns of equal width */
            grid-gap: 20px;
            /* Gap between grid items */
            padding: 20px;

            .card {
              background-color: #fff9d0;
              padding: 10px;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;

              p {
                text-align: left;
                margin-left: 10px;
              }
            }
          }
        }
      }

      .whyEngageImage {
        width: 32%;
        height: 85%;
        z-index: 2;
        margin-left: 6rem;
        position: relative;
      }

      .whyEngageText {
        z-index: 3;
        margin: 0px 0px 10rem 2rem;

        h1 {
          font-weight: 1000;
        }
      }
    }

    // .row-2 {
    //   .col {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     .card {
    //       width: 392px;
    //       height: 417px;
    //       border-top: none;
    //       box-shadow: none;
    //       transition: width 2s height 2s;
    //       transition-timing-function: ease-in;
    //       .card-img-container {
    //         padding-inline: 62px;
    //       }
    //       margin-bottom: 60px;
    //       .card-body {
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         .card-text {
    //           width: 296px;
    //           height: 64px;
    //           text-align: center;
    //           font-size: 1.2rem;
    //           font-family: "MontserratMedium" !important;
    //           letter-spacing: 0px;
    //           color: #4a5456;
    //           opacity: 1;
    //         }
    //       }
    //     }
    //   }
    // }
  }

  .ratingContainer {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 5%;

    div {
      width: 33.33%;

      div {
        width: 100%;

        hr {
          border-color: black;
          border-width: 4px;
        }
      }

      .fivestar_imgbox {
        width: 100%;
        position: relative;
        height: auto;
        border: 2px solid black;
        border-radius: 170px;
        text-align: center;

        .fivestarImage {
          width: 80%;
          height: 130%;
          position: absolute;
          top: -102px;
          /* Adjust this value to position the image as desired */
          left: 50%;
          transform: translateX(-50%);
        }

        p {
          margin-top: 2rem;
          margin-left: 50px;
          margin-right: 50px;
          font-size: 20px;
        }
      }
    }
  }

  .sutaFavouritesContainer {
    width: 100%;
    height: auto;
    text-align: center;
    margin-top: 6rem;

    .sutaFavorableTable {
      width: 100%;
      height: 80%;
      background-size: cover;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 50px 0 50px 0;

      .grid-container {
        width: 80%;
        height: 60%;
        display: grid;
        grid-template-columns: repeat(6, 1fr);

        .grid-item {
          padding: 20px;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 20px;
        }
      }

      .grid-container > div:not(:nth-child(6n + 1)) {
        border-left: 3px solid white;
      }

      .grid-container > div:nth-child(n + 7):nth-child(-n + 12) {
        border-top: 3px solid white;
      }
    }
  }

  .whypeoContainer {
    width: 100%;
    height: 500px;

    .whypeobg {
      background-image: url(../images/WhypeoBG.png);
      width: 100%;
      height: 100%;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      display: flex;
      align-items: center;
      justify-content: center;

      .whyPeoBox {
        width: 60%;
        height: auto;
        background-color: #f1f1f1;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
          rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        text-align: center;

        p {
          font-size: 18px;
        }
      }
    }
  }

  .scroll-icon-banner-container {
    width: 100%;
    height: auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding: 20px 0 20px 0;

    .scrollIconBanner {
      width: 100%;
      height: 50%;
    }
  }

  $footer-bg-color: #ffd251;
  $footer-padding: 20px;
  $footer-text-color: #6c757d;
  $footer-link-color: #007bff;

  .homeFooterContainer {
    background-color: $footer-bg-color;
    padding-bottom: 25px;
    text-align: center;
    align-items: center;
    color: $footer-text-color;
    width: 100%;
    display: flex;

    .footer_partners_section {
      width: 34%;
      margin-top: 25px;

      .partnersLogo {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;

        img {
          max-width: 150px;
        }
      }
    }

    .footer_logo_section {
      width: 33%;

      .footer_logos {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;

        img {
          max-width: 150px;
        }
      }
    }

    .footer_contact_section {
      display: flex;
      justify-content: center;
      align-items: center;

      //justify-content: space-around;
      padding-top: 20px;
      width: 33%;

      .vertical-divider {
        width: 1px;
        height: 80px;
        background-color: $footer-text-color;
        margin: 0 20px;
        z-index: 10;
      }

      .footerParts {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .footer_email {
          margin-bottom: 10px;

          p {
            margin: 0;
            font-size: 16px;

            &:first-of-type {
              margin-bottom: 5px;
              font-weight: bold;
            }
          }

          a {
            display: inline-block;

            img {
              width: 30px;
              height: 30px;
            }
          }
        }

        .icons {
          display: flex;
          justify-content: center;
          justify-content: space-between;
          justify-content: space-around;
        }

        .footer_icons {
          width: 120px;
        }
      }
    }
  }

  .our-services .row-2 .col .card:hover {
    width: 417px;
    height: 420px;
  }

  .why-us {
    // margin-top: 110px;
    background-color: #f4f4f4;
    padding-top: 20px;

    .row-2 {
      background-color: #7f3e99;
      height: auto;
      padding-top: 10px;
      padding-bottom: 120px;
      background-position: center center !important;
      background-repeat: no-repeat !important;
      // background-attachment: fixed !important;
      background-size: cover !important;

      .col {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }

  .unique-about-us {
    .row-2 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      // height: 650px;
      .play-vid-container {
        width: 50%;
        height: 450px;

        iframe {
          height: 100%;
          width: 100%;
        }

        .vid-img {
          opacity: 1;
        }

        .vid-img-cover {
          opacity: 1.63;
          margin-top: -65%;
          // transform: translate(-50%, -60%);
        }

        .play-button {
          margin-top: -55%;
          margin-left: 50%;
          transform: translate(-50%, -60%);
          opacity: 1;
        }
      }
    }

    .row-1 {
      margin-top: 60px;
    }

    margin-bottom: 60px;
  }

  .learn-more-about-us {
    width: 100%;
    background-color: #447cb9;
    height: auto;
    border-radius: 20px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    .get-quote-now {
      height: 150px;
      width: 150px;
      border-radius: 50%;
      background-color: white;
      position: absolute;
      bottom: 170%;
      right: 18%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      animation-duration: 1s;
      // animation-name: slideinquotecontainer;
      animation-iteration-count: infinite;
      animation-direction: alternate-reverse;
      box-shadow: none;

      .get-quote-now-two {
        height: 175px;
        width: 175px;
        border-radius: 50%;
        background-image: linear-gradient(to bottom, #fe5d50, #fbce23);
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 33px;
        cursor: pointer;
        animation-duration: 1s;
        // animation-name: slideinquote;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;
        box-shadow: none;
        font-size: 1.6rem;
      }
    }

    // .row-1 {
    //   margin-bottom: 50px !important;
    //   text-align: left;
    // }

    .form-col {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      //padding-right: 30px;

      input:nth-child(1) {
        width: 80%;
        margin-top: 25%;
      }

      input,
      .learn-comment {
        margin-top: 12px;
        padding-top: 7px;
        padding-bottom: 7px;
        padding-left: 24px;
        width: 80%;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: inset 1px 0px 6px #00000029;
        border: 1px solid #e1e1e1;
        border-radius: 10px;
        opacity: 1;
        font-family: "MontserratMedium" !important;
        color: #080808;
      }

      .btn-container {
        display: flex;
        justify-content: center;
        margin-top: 10%;
        margin-bottom: 10%;

        .btn {
          color: white;
          width: 126px;
          padding: 10px;
          border-radius: 15px;
          font-size: 18px;
        }
      }
    }
  }

  .testimonial {
    margin-top: 63px;
    width: auto;
    height: 508px;
    bottom: 70px;
    right: 0px;
    background-repeat: no-repeat !important;
    // background-attachment: fixed;
    background-size: cover !important;
    background-position: center center !important;

    .row {
      height: 100%;

      .quotation-mark {
        width: 162px;
        height: 162px;
      }

      [class*="col-"] {
        display: flex;
        justify-content: center;
        align-items: center;

        .testimonial-text-container {
          text-align: left;
          width: 100%;
          color: #ffffff;
          font-size: 16px;

          .italic-text-comp {
            color: #d8d8d8;
            margin-bottom: 0px;
          }

          .italic-text {
            width: 80% !important;
          }
        }
      }

      .large-col {
        padding: 50px;
      }
    }
  }

  .login-container {
    position: fixed;
    display: flex;
    width: 100%;
    height: 35px;
    background-color: #ffffff;
    top: 7px;
    right: 20px;
    align-items: center;
    justify-content: end;
    background-color: transparent;
    z-index: 1001;

    .dropdown-menu {
      background-color: #fff5dc;
    }

    .dropdown-item:hover {
      background-color: #ffdb73;
      color: #080808;
    }

    .login-logout-link {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 8px 15px;
      margin: 10px 1rem 0 0;
      background-color: #427cbc;
      color: white;
      border: none;
      border-radius: 10px;
      font-size: 16px;
      cursor: pointer;
      text-decoration: none;

      transition: background-color 0.3s ease;
      gap: 5px;
    }

    .login-logout-link:hover {
      background-color: #2c537c;
    }

    .dropdown-toggle {
      font-weight: 900;
    }

    a {
      color: rgb(0, 0, 0);
      cursor: pointer;
      font-weight: 900;
      font-size: large;
      margin-right: 30px;
    }

    a:hover {
      color: #f8af16;
      text-decoration: none;
    }
  }

  .learn-more-about-us {
    .get-quote-now {
      .get-quote-now-two {
        .get-quote-now-text {
          text-align: center;
          color: white;
        }
      }
    }
  }

  .fixed-ball {
    position: fixed;
    z-index: 5;
    right: 20px;
    bottom: 20px;
    width: 96px;
    height: 96px;
    border-radius: 57px;
    background-color: #f8af16;
    font-size: 12px;
    color: white;
    text-align: center;
    padding-top: 25px;
    cursor: pointer;
    animation-duration: 2s;
    // animation-name: slidein;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    box-shadow: none;
  }
}

.header-logo-style {
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  .hotscheduleslogo {
    .hotscheduleslogowidth {
      width: 350px;
      margin-left: -1rem;
    }
  }
  .affinitylogo {
    .affinitylogo1 {
      width: 393px;
    }
  }
}
.downloadbtnstyle {
  display: flex;
  gap: 5px;
  color: #008e1b;
  font-weight: bold;

  font-size: 20px;
  text-decoration: underline;
  cursor: pointer;
  .downlaodnewlogo {
    width: 32px;
  }
}

.location-layout {
  display: flex;
}

.downloadbtnstyle {
  display: flex;
  gap: 5px;
  color: #008e1b;
  font-weight: bold;

  font-size: 20px;
  text-decoration: underline;
  cursor: pointer;
  .download-image {
    width: 32px;
  }
}

.Dynamic-Render {
  align-items: left;
  width: 90%;
  height: 100%;
  margin-left: 10%;
  margin-top: 20px;
  font-family: MontserratRegular;
  font-weight: bold;
  font-size: 1rem;
  color: #212529;
}

.Dynamic-Render li {
  margin-bottom: 10px;
}
.file-upload-label {
  cursor: pointer;
  padding: 5px 10px;
  background-color: white;
  color: black;
  border: 1px solid orange;
  border-radius: 6px;
  width: 10%;
  font-size: 12px;
}
// Media Queries for Home / Landing Page

@media only screen and (max-width: 480px) {
  .file-upload-label {
    cursor: pointer;
    padding: 5px 10px;
    background-color: white;
    color: black;
    border: 1px solid orange;
    border-radius: 6px;
    width: 40%;
    font-size: 12px;
  }

  .Dynamic-Render {
    align-items: left;
    width: 90%;
    height: 100%;

    margin-top: 20px;
    font-family: MontserratRegular;
    font-weight: bold;
    font-size: 1rem;
    color: #212529;
  }

  .Dynamic-Render li {
    margin-bottom: 10px;
  }
  .downloadbtnstyle {
    font-size: 13px;
    cursor: pointer;
    .downlaodnewlogo {
      width: 15px;
    }
  }
  .quote-table .bindquotebtnstyle .bindquotebtnstyletwo .bindquotebtn {
    width: 86%;
    height: 110px;
    margin: auto;
    border: 2px solid transparent;
    border-image: linear-gradient(to right, #13a0f9, #faa541);
    border-image-slice: 1;
    padding: 5px;
    display: flex;
    justify-content: center;
    border-radius: 1em;
    font-size: 17px;
  }

  .newquote-mainbody
    .newquote-content
    #locationPayrollContainer
    .location-payroll-container {
    margin-left: 44px;
    margin-right: 44px;
    margin-bottom: 5px;
  }
  .location-layout {
    display: grid;
  }
  .home-login {
    width: 100%;
    height: 60px;
    padding: 0px;
    background-color: #ffc423;
    display: flex;
    justify-content: space-around;
    //  grid-gap: 20px;
    gap: 5px;
    justify-content: none;
    align-items: center;
    position: fixed;
    z-index: 1000;
    .user-dropdown {
      font-size: 8px;
    }
    .menustyle {
      font-size: 8px;
    }
  }
  .newquote-mainbody {
    .newquote-content {
      #compProfile {
        .company-profile-container {
          margin-left: 20px;
          margin-right: 20px;
          margin-bottom: 5px;
        }
        .question-list-container {
          margin-left: 20px;
          margin-right: 20px;
          margin-bottom: 5px;
          .paycycle-container {
            .requstedAdminRow {
              .requestedAdminContent {
                width: 130px;
              }
            }
            .paycycle-style {
              margin-left: 8px;
              display: grid;
              gap: 13px;
            }
          }
        }
      }
    }
  }

  .external-box {
    width: 80%;
    margin: auto;
    .no-login-user-heading-check {
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      border: 1px solid #ddd;
      border-radius: 8px;
      background-color: #f9f9f9;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      .check-company-info {
        text-align: left;
        color: #427cbc;
        font-size: 12px;
        font-weight: bolder;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
  @keyframes slideinquoteA {
    from {
      width: 95px;
      height: 95px;
      font-size: 12px !important;
      box-shadow: none;
    }

    to {
      width: 110px;
      height: 110px;
      font-size: 14px !important;
      box-shadow: -3px 3px 2px white, 3px 3px 2px white;
    }
  }

  .header-logo-style {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .hotscheduleslogo {
      .hotscheduleslogowidth {
        width: 100px;
        margin-left: -1rem;
      }
    }
    .affinitylogo {
      .affinitylogo1 {
        width: 100px;
      }
    }
  }

  @keyframes slideinquotecontainerA {
    from {
      width: 110px;
      height: 110px;
      font-size: 12px !important;
      box-shadow: none;
    }

    to {
      width: 120px;
      height: 120px;
      font-size: 17px !important;
      box-shadow: -2px 3px 1px white, 3px 3px 1px white;
    }
  }
  .header-logo-style {
    .hotscheduleslogo {
      width: 75px;
    }
  }

  .home-landing-page {
    .bringingcolumn {
      flex-direction: row !important;

      .logoreduce {
        margin-top: 130px;
        height: 80px !important;
      }

      .logoreducing {
        height: 90px !important;
        width: 125px;
        margin-top: 125px;
      }
    }

    .home-banner {
      // .banner-container {
      //   min-height: 100vh !important;
      //   height: 100vh !important;

      //   // .whitebox-banner {
      //   //   height: 114px !important;
      //   // }
      // }

      // .banner-content-container {
      //   flex-direction: column-reverse;
      //   top: 21rem !important;

      //   .banner-images-container {
      //     .banner-text-box {
      //       margin-top: 10px;

      //       .banner-stamps-text-container {
      //         .bannerStampText {
      //           font-size: 0.5rem;
      //         }
      //       }
      //     }

      //     h1 {
      //       font-size: 1.2rem;
      //     }

      //     h5 {
      //       font-size: 0.65rem;
      //     }

      //     .banner-two-people-image {
      //       height: 105% !important;
      //       width: 64% !important;
      //       top: 9rem;
      //       right: 10px !important;
      //     }
      //   }
      // }
      .banner-container {
        .zonefirst {
          .headers-companies {
            margin-top: 20px;

            .header-sompany-logo-style {
              h1 {
                font-size: 2rem;
              }
            }

            .bannerStampText {
              h5 {
                font-size: 0.8rem;
              }
            }
          }

          .image-banner-style {
            margin-top: -60px;

            .banner-two-people-image {
              width: 100%;
            }
          }
        }
      }

      .et_pb_bottom_inside_divider {
        display: none !important;
      }

      .learn-more-about-us {
        width: 95%;
        margin: auto;

        .get-quote-now {
          // animation-name: slideinquotecontainerA;
          bottom: 90%;
          right: 50%;
          left: 50%;

          // .get-quote-now-two {
          //   // animation-name: slideinquoteA;
          // }

          .get-quote-now-text {
            font-size: 1rem;
          }
        }

        .form-col {
          // display: flex;
          // flex-direction: column;
          // justify-content: center;
          // align-items: center;
          width: 100%;

          .btn-container {
            margin-left: 50px;
          }
        }
      }
    }

    .our-services {
      .why-engage-section {
        .why-engage-container {
          .why-engage-container-box {
            align-items: end;
            width: 90%;
            margin-top: 7rem;
          }

          .why-engageImagebox {
            height: auto;
            border-radius: 20px 0 0 20px;

            .cardsContainer {
              grid-template-columns: repeat(1, 1fr);
              /* 2 columns of equal width */
              grid-gap: 2px;
              /* Gap between grid items */
              padding: 10px;

              .card {
                background-color: #fff9d0;
                padding: 2px;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;

                p {
                  font-size: 0.7rem;
                  margin-bottom: 0 !important;
                }
              }
            }
          }
        }

        .whyEngageImage {
          margin-left: 0.5rem;
          width: 40%;
          height: 90%;
        }

        .whyEngageText {
          margin: 0px 0rem 25rem -3rem;

          h1 {
            font-size: 1.4rem;
            font-weight: 1000;
          }
        }
      }
    }

    .ratingContainer {
      margin-top: 25%;

      div:nth-child(1),
      div:nth-child(3) {
        width: 30%;
      }

      div:nth-child(2) {
        width: 40%;

        .fivestar_imgbox {
          width: 100% !important;

          .fivestarImage {
            width: 80%;
            height: 65%;
            top: -48%;
          }

          p {
            margin-top: 1.5rem;
            margin-left: 10px;
            margin-right: 10px;
            font-size: 0.8rem;
          }
        }
      }
    }

    .sutaFavouritesContainer {
      h1 {
        font-size: 1.8rem;
      }

      .sutaFavorableTable {
        .grid-container {
          width: 100% !important;
          grid-template-columns: repeat(3, 1fr);

          .grid-item {
            font-size: 9px;
          }
        }

        .grid-container > div:not(:nth-child(6n + 1)) {
          border-left: none !important;
        }

        .grid-container > div:not(:nth-child(3n + 1)) {
          border-left: 3px solid white !important;
        }

        .grid-container > div:nth-child(n + 4):nth-child(-n + 12) {
          border-top: 3px solid white;
        }
      }
    }

    .whypeoContainer {
      height: 300px;

      .whypeobg {
        height: 100%;

        .whyPeoBox {
          width: 90%;

          h1 {
            font-size: 1.8rem;
          }

          p {
            font-size: 0.8rem;
            text-align: left;
            padding: 20px !important;
          }
        }
      }
    }

    .homeFooterContainer {
      background-color: #ffd251;
      padding-bottom: 25px;
      text-align: center;
      align-items: center;
      color: #6c757d;
      width: 100%;
      display: grid;

      .footer_partners_section {
        width: 100%;
        margin-top: 5px;

        .text {
          font-size: 10px;
        }

        .partnersLogo {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 30px;
          margin-top: -5px;

          img {
            max-width: 100px;
          }
        }
      }

      .footer_logo_section {
        width: 100%;

        .footer_logos {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 30px;

          img {
            max-width: 80px;
          }
        }
      }

      .footer_contact_section {
        display: flex;
        justify-content: center;
        align-items: center;

        //justify-content: space-around;
        padding-top: 0px;
        width: 90%;

        .vertical-divider {
          width: 1px;
          height: 50px;
          background-color: #6c757d;
          margin: 0 0px;
          z-index: 10;
        }

        .footerParts {
          display: flex;
          flex-direction: column;
          align-items: center;

          .footer_email {
            margin-bottom: 10px;

            p {
              margin: 0;
              font-size: 10px;

              &:first-of-type {
                margin-bottom: 5px;
                font-weight: bold;
              }

              b {
                font-size: 10px;
              }
            }

            a {
              display: inline-block;

              img {
                width: 30px;
                height: 30px;
              }
            }
          }

          .icons {
            display: flex;
            margin-top: -14px;
            justify-content: center;
            justify-content: space-between;
            justify-content: space-around;
          }

          .footer_icons {
            width: 73px;
            font-size: 10px;
          }
        }
      }
    }
  }
  .dropZonesContainer {
    .customDropZone,
    .customDropZone-uw {
      width: 0px;
      .dropzone {
        text-align: center;
        width: 300px;
        height: 40px;
        padding-top: 2px;
        border: 2px solid #faa558;
        background-color: #fafafa;
        border-radius: 10px;
        color: #8a5656;

        .dropZoneText {
          margin-top: 7px;
          cursor: pointer;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }

    .customDropZone-dashborad {
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;

      padding-inline: 5px;

      .customdropcontent {
        font-size: 15px;
      }

      .dropzone {
        text-align: center;
        width: 100%;
        height: 40px;
        padding-top: 2px;
        border: 2px solid #faa558;
        background-color: #fafafa;
        border-radius: 10px;
        color: #8a5656;

        .dropZoneText {
          margin-top: 7px;
          cursor: pointer;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }

    .customDropZone-uw {
      //height: 285px !important;
      width: 266px;
      margin-top: 50px;
      display: flex;
      align-items: center;

      .dropzone {
        height: 100px !important;
      }
    }

    .customDropZone-dashborad {
      height: 130px !important;
      display: flex;
      align-items: center;

      .dropzone {
        height: 100px !important;
      }
    }
  }
}

@media all and (min-width: 481px) and (max-width: 640px) {
  .file-upload-label {
    cursor: pointer;
    padding: 5px 10px;
    background-color: white;
    color: black;
    border: 1px solid orange;
    border-radius: 6px;
    width: 20%;
    font-size: 12px;
  }
  .dropZonesContainer {
    .customDropZone,
    .customDropZone-uw {
      width: 276px;

      .dropzone {
        text-align: center;
        width: 300px;
        height: 40px;
        padding-top: 2px;
        border: 2px solid #faa558;
        background-color: #fafafa;
        border-radius: 10px;
        color: #8a5656;

        .dropZoneText {
          margin-top: 7px;
          cursor: pointer;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }

    .customDropZone-dashborad {
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;

      padding-inline: 5px;

      .customdropcontent {
        font-size: 15px;
      }

      .dropzone {
        text-align: center;
        width: 100%;
        height: 40px;
        padding-top: 2px;
        border: 2px solid #faa558;
        background-color: #fafafa;
        border-radius: 10px;
        color: #8a5656;

        .dropZoneText {
          margin-top: 7px;
          cursor: pointer;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }

    .customDropZone-uw {
      //height: 285px !important;
      margin-top: 50px;
      display: flex;
      align-items: center;

      .dropzone {
        height: 100px !important;
      }
    }

    .customDropZone-dashborad {
      height: 130px !important;
      display: flex;
      align-items: center;

      .dropzone {
        height: 100px !important;
      }
    }
  }
  .downloadbtnstyle {
    font-size: 15px;
    cursor: pointer;
    .downlaodnewlogo {
      width: 20px;
    }
  }
  .quote-table .bindquotebtnstyle .bindquotebtnstyletwo .bindquotebtn {
    width: 70%;
    height: 110px;
    margin: auto;
    border: 2px solid transparent;
    border-image: linear-gradient(to right, #13a0f9, #faa541);
    border-image-slice: 1;
    padding: 5px;
    display: flex;
    justify-content: center;
    border-radius: 1em;
    font-size: 17px;
  }
  .location-layout {
    display: grid;
  }
  .home-login {
    width: 100%;
    height: 60px;
    padding: 3px;
    background-color: #ffc423;
    display: flex;
    justify-content: space-around;
    grid-gap: 20px;
    gap: 20px;
    justify-content: none;
    align-items: center;
    position: fixed;
    z-index: 1000;
    .user-dropdown {
      font-size: 12px;
    }
    .menustyle {
      font-size: 12px;
    }
  }
  .newquote-mainbody {
    .newquote-content {
      #compProfile {
        .company-profile-container {
          margin-left: 20px;
          margin-right: 20px;
          margin-bottom: 5px;
        }
        .question-list-container {
          margin-left: 20px;
          margin-right: 20px;
          margin-bottom: 5px;
          .paycycle-container {
            .requstedAdminRow {
              .requestedAdminContent {
                width: auto;
              }
            }
            .paycycle-style {
              margin-left: 8px;
              display: flex;
            }
          }
        }
      }
    }
  }

  .external-box {
    width: 80%;
    margin: auto;
    .no-login-user-heading-check {
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      border: 1px solid #ddd;
      border-radius: 8px;
      background-color: #f9f9f9;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      .check-company-info {
        text-align: left;
        color: #427cbc;
        font-size: 12px;
        font-weight: bolder;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
  .header-logo-style {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .hotscheduleslogo {
      .hotscheduleslogowidth {
        width: 100px;
        margin-left: -1rem;
      }
    }
    .affinitylogo {
      .affinitylogo1 {
        width: 100px;
      }
    }
  }
  .home-landing-page {
    .bringingcolumn {
      flex-direction: row !important;

      .logoreduce {
        margin-top: 130px;
        height: 100px !important;
      }

      .logoreducing {
        height: 115px !important;
        margin-top: 125px;
      }
    }
    .our-services {
      .why-engage-section {
        .why-engage-container {
          .why-engage-container-box {
            align-items: end;
            width: 90%;
            margin-top: 7rem;
          }

          .why-engageImagebox {
            height: auto;
            border-radius: 20px 0 0 20px;

            .cardsContainer {
              grid-template-columns: repeat(1, 1fr);
              /* 2 columns of equal width */
              grid-gap: 2px;
              /* Gap between grid items */
              padding: 10px;

              .card {
                background-color: #fff9d0;
                padding: 2px;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;

                p {
                  font-size: 0.7rem;
                  margin-bottom: 0 !important;
                }
              }
            }
          }
        }

        .whyEngageImage {
          margin-left: 0.5rem;
          width: 40%;
          height: 90%;
        }

        .whyEngageText {
          margin: 0px 0rem 25rem -3rem;

          h1 {
            font-size: 1.4rem;
            font-weight: 1000;
          }
        }
      }
    }

    .homeFooterContainer {
      background-color: #ffd251;
      padding-bottom: 25px;
      text-align: center;
      align-items: center;
      color: #6c757d;
      width: 100%;
      display: grid;

      .footer_partners_section {
        width: 100%;
        margin-top: 5px;

        .text {
          font-size: 10px;
        }

        .partnersLogo {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 30px;
          margin-top: -5px;

          img {
            max-width: 100px;
          }
        }
      }

      .footer_logo_section {
        width: 100%;

        .footer_logos {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 30px;

          img {
            max-width: 80px;
          }
        }
      }

      .footer_contact_section {
        display: flex;
        justify-content: center;
        align-items: center;

        //justify-content: space-around;
        padding-top: 0px;
        width: 90%;

        .vertical-divider {
          width: 1px;
          height: 50px;
          background-color: #6c757d;
          margin: 0 0px;
          z-index: 10;
        }

        .footerParts {
          display: flex;
          flex-direction: column;
          align-items: center;

          .footer_email {
            margin-bottom: 10px;

            p {
              margin: 0;
              font-size: 10px;

              &:first-of-type {
                margin-bottom: 5px;
                font-weight: bold;
              }

              b {
                font-size: 10px;
              }
            }

            a {
              display: inline-block;

              img {
                width: 30px;
                height: 30px;
              }
            }
          }

          .icons {
            display: flex;
            margin-top: -14px;
            justify-content: center;
            justify-content: space-between;
            justify-content: space-around;
          }

          .footer_icons {
            width: 73px;
            font-size: 10px;
          }
        }
      }
    }

    .home-banner {
      // .banner-container {
      //   min-height: 100vh !important;
      //   height: 100vh !important;

      //   // .whitebox-banner {
      //   //   height: 100vh !important;
      //   //   margin-top: 4.9rem !important;
      //   // }
      // }

      // .banner-content-container {
      //   height: auto !important;
      //   top: 8rem !important;

      //   .banner-form-container {
      //     width: 30% !important;
      //     padding-left: 0 !important;
      //     padding-right: 0 !important;
      //   }

      //   .banner-images-container {
      //     width: 65% !important;

      //     .banner-text-box {
      //       margin-top: 50px;

      //       .banner-stamps-text-container {
      //         padding-right: 5px !important;
      //         margin-top: -10px;

      //         .bannerStampText {
      //           font-size: 0.3rem;
      //           padding: 6px 5px 5px 9px !important;
      //         }
      //       }
      //     }

      //     h1 {
      //       font-size: 1rem;
      //     }

      //     h5 {
      //       font-size: 0.5rem;
      //     }

      //     .banner-people-image-box {
      //       margin-top: 34rem !important;

      //       .banner-two-people-image {
      //         height: 59% !important;
      //         width: 66% !important;
      //         top: 9rem;
      //         right: 0px !important;
      //         margin-top: -9rem !important;
      //         right: -15px !important;
      //       }
      //     }
      //   }
      // }
      .banner-container {
        .zonefirst {
          .headers-companies {
            margin-top: 10px;

            .header-sompany-logo-style {
              h1 {
                font-size: 2.2rem;
              }
            }

            .bannerStampText {
              h5 {
                font-size: 1.1rem;
              }
            }
          }

          .image-banner-style {
            margin-top: -60px;

            .banner-two-people-image {
              width: 100%;
            }
          }
        }
      }

      .learn-more-about-us {
        width: 80%;
        margin: auto;

        .get-quote-now {
          height: 80px;
          width: 80px;
          bottom: 80%;
          right: 50%;
          left: 50%;

          .get-quote-now-two {
            height: 70px;
            width: 70px;
          }

          .get-quote-now-text {
            font-size: 0.55rem;
          }
        }

        .form-col {
          width: 100%;

          // display: flex;
          // flex-direction: column;
          // justify-content: center;
          // align-items: center;
          input {
            padding: 9px 0 9px 6px;
            font-size: 9px !important;
            margin-bottom: 2px;
          }

          .btn-container {
            .btn {
              padding: 12px;
              width: 100%;
              font-size: 11px;
            }
          }
        }
      }

      .et_pb_bottom_inside_divider {
        top: 28rem !important;
      }
    }
    .ratingContainer {
      margin-top: 15%;

      div:nth-child(1),
      div:nth-child(3) {
        width: 30%;
      }

      div:nth-child(2) {
        width: 40%;

        .fivestar_imgbox {
          width: 100% !important;

          .fivestarImage {
            width: 80%;
            height: 65%;
            top: -48%;
          }

          p {
            margin-top: 1.5rem;
            margin-left: 10px;
            margin-right: 10px;
            font-size: 0.8rem;
          }
        }
      }
    }
    .sutaFavouritesContainer {
      h1 {
        font-size: 1.8rem;
      }

      .sutaFavorableTable {
        .grid-container {
          grid-template-columns: repeat(3, 1fr);

          .grid-item {
            font-size: 16px;
          }
        }

        .grid-container > div:not(:nth-child(6n + 1)) {
          border-left: none !important;
        }
        .grid-container > div:not(:nth-child(6n + 1)) {
          border-left: none !important;
        }

        .grid-container > div:not(:nth-child(3n + 1)) {
          border-left: 3px solid white !important;
        }
        .grid-container > div:not(:nth-child(3n + 1)) {
          border-left: 3px solid white !important;
        }

        .grid-container > div:nth-child(n + 4):nth-child(-n + 12) {
          border-top: 3px solid white;
        }
      }
    }

    .whypeoContainer {
      height: 300px;

      .whypeobg {
        height: 100%;

        .whyPeoBox {
          width: 90%;

          h1 {
            font-size: 1.8rem;
          }

          p {
            font-size: 0.8rem;
            text-align: left;
            padding: 20px !important;
          }
        }
      }
    }
  }
}

@media all and (min-width: 641px) and (max-width: 768px) {
  .file-upload-label {
    cursor: pointer;
    padding: 5px 10px;
    background-color: white;
    color: black;
    border: 1px solid orange;
    border-radius: 6px;
    width: 30%;
    font-size: 12px;
  }
  .dropZonesContainer {
    .customDropZone,
    .customDropZone-uw {
      width: 276px;

      .dropzone {
        text-align: center;
        width: 300px;
        height: 40px;
        padding-top: 2px;
        border: 2px solid #faa558;
        background-color: #fafafa;
        border-radius: 10px;
        color: #8a5656;

        .dropZoneText {
          margin-top: 7px;
          cursor: pointer;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }

    .customDropZone-dashborad {
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;

      padding-inline: 5px;

      .customdropcontent {
        font-size: 15px;
      }

      .dropzone {
        text-align: center;
        width: 100%;
        height: 40px;
        padding-top: 2px;
        border: 2px solid #faa558;
        background-color: #fafafa;
        border-radius: 10px;
        color: #8a5656;

        .dropZoneText {
          margin-top: 7px;
          cursor: pointer;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }

    .customDropZone-uw {
      //height: 285px !important;
      margin-top: 50px;
      display: flex;
      align-items: center;

      .dropzone {
        height: 100px !important;
      }
    }

    .customDropZone-dashborad {
      height: 130px !important;
      display: flex;
      align-items: center;

      .dropzone {
        height: 100px !important;
      }
    }
  }
  .downloadbtnstyle {
    font-size: 15px;
    cursor: pointer;
    .downlaodnewlogo {
      width: 20px;
    }
  }
  .quote-table .bindquotebtnstyle .bindquotebtnstyletwo .bindquotebtn {
    width: 60%;
    height: 110px;
    margin: auto;
    border: 2px solid transparent;
    border-image: linear-gradient(to right, #13a0f9, #faa541);
    border-image-slice: 1;
    padding: 5px;
    display: flex;
    justify-content: center;
    border-radius: 1em;
    font-size: 17px;
  }
  .location-layout {
    display: grid;
  }
  .newquote-mainbody {
    .newquote-content {
      #compProfile {
        .company-profile-container {
          margin-left: 20px;
          margin-right: 20px;
          margin-bottom: 5px;
        }
        .question-list-container {
          margin-left: 20px;
          margin-right: 20px;
          margin-bottom: 5px;
          .paycycle-container {
            .requstedAdminRow {
              .requestedAdminContent {
                width: auto;
              }
            }
            .paycycle-style {
              margin-left: 8px;
              display: flex;
            }
          }
        }
      }
    }
  }
  .external-box {
    width: 40%;
    margin: auto;
    .no-login-user-heading-check {
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      border: 1px solid #ddd;
      border-radius: 8px;
      background-color: #f9f9f9;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      .check-company-info {
        text-align: left;
        color: #427cbc;
        font-size: 15px;
        font-weight: bolder;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
  .header-logo-style {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .hotscheduleslogo {
      .hotscheduleslogowidth {
        width: 200px;
        margin-left: -1rem;
      }
    }
    .affinitylogo {
      .affinitylogo1 {
        width: 200px;
      }
    }
  }
  .home-landing-page {
    .bringingcolumn {
      flex-direction: row !important;

      .logoreduce {
        margin-top: 130px;
        height: 120px !important;
      }

      .logoreducing {
        height: 135px !important;
        margin-top: 125px;
      }
    }

    .home-banner {
      // .banner-container {
      //   min-height: 100vh !important;
      //   height: 100vh !important;

      //   // .whitebox-banner {
      //   //   height: 125px !important;
      //   //   margin-top: 6.4rem !important;
      //   // }
      // }

      // .banner-content-container {
      //   height: auto !important;
      //   top: 12.5rem !important;

      //   .banner-form-container {
      //     width: 30% !important;
      //     padding-left: 0 !important;
      //     padding-right: 0 !important;
      //   }

      //   .banner-images-container {
      //     width: 65% !important;

      //     .banner-text-box {
      //       margin-top: 50px;

      //       .banner-stamps-text-container {
      //         padding-right: 5px !important;
      //         margin-top: 26px;

      //         .bannerStampText {
      //           font-size: 0.3rem;
      //           padding: 6px 5px 5px 9px !important;
      //         }
      //       }
      //     }

      //     h1 {
      //       font-size: 1rem;
      //     }

      //     h5 {
      //       font-size: 0.65rem;
      //     }

      //     .banner-people-image-box {
      //       margin-top: 34rem !important;

      //       .banner-two-people-image {
      //         height: 63% !important;
      //         width: 66% !important;
      //         top: 9rem;
      //         margin-top: -10rem !important;
      //         right: -18px !important;
      //       }
      //     }
      //   }
      // }
      .banner-container {
        .zonefirst {
          .headers-companies {
            margin-top: 30px;

            .header-sompany-logo-style {
              h1 {
                font-size: 2.5rem;
              }
            }

            .bannerStampText {
              h5 {
                font-size: 1.1rem;
              }
            }
          }

          .image-banner-style {
            margin-top: -60px;

            .banner-two-people-image {
              width: 80%;
            }
          }
        }
      }

      .banner-two-people-image {
        margin-top: 43px;
        width: 50%;
        height: 50%;
      }

      .learn-more-about-us {
        width: 55%;
        margin: auto;

        .get-quote-now {
          height: 80px;
          width: 80px;
          bottom: 90%;
          right: 50%;
          left: 50%;

          .get-quote-now-two {
            height: 70px;
            width: 70px;
          }

          .get-quote-now-text {
            font-size: 0.6rem;
          }
        }

        .form-col {
          width: 100%;

          // display: flex;
          // flex-direction: column;
          // justify-content: center;
          // align-items: center;
          input {
            padding: 9px 0 9px 6px;
            font-size: 11px !important;
            margin-top: 12px;
            margin-bottom: 10px;
          }

          input:nth-child(1) {
            margin-top: 3rem !important;
          }

          .btn-container {
            .btn {
              padding: 9px;
              width: 100%;
              font-size: 11px;
            }
          }
        }
      }

      .et_pb_bottom_inside_divider {
        top: 35rem !important;
      }
    }

    .our-services {
      .why-engage-section {
        .why-engage-container {
          .why-engage-container-box {
            align-items: end;
            width: 90%;
            margin-top: 7rem;
          }

          .why-engageImagebox {
            height: auto;
            border-radius: 20px 0 0 20px;

            .cardsContainer {
              grid-template-columns: repeat(1, 1fr);
              /* 2 columns of equal width */
              grid-gap: 2px;
              /* Gap between grid items */
              padding: 10px;

              .card {
                background-color: #fff9d0;
                padding: 2px;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;

                p {
                  font-size: 0.7rem;
                  margin-bottom: 0 !important;
                }
              }
            }
          }
        }

        .whyEngageImage {
          margin-left: 0.5rem;
          width: 40%;
          height: 90%;
        }

        .whyEngageText {
          margin: 0px 0rem 16rem -3rem;

          h1 {
            font-size: 1.4rem;
            font-weight: 1000;
          }
        }
      }
    }

    .ratingContainer {
      margin-top: 15%;

      div:nth-child(1),
      div:nth-child(3) {
        width: 30%;
      }

      div:nth-child(2) {
        width: 40%;

        .fivestar_imgbox {
          width: 100% !important;

          .fivestarImage {
            width: 80%;
            height: 95%;
            top: -70%;
          }

          p {
            margin-top: 1.5rem;
            margin-left: 10px;
            margin-right: 10px;
            font-size: 0.8rem;
          }
        }
      }
    }

    .sutaFavouritesContainer {
      h1 {
        font-size: 1.8rem;
      }

      .sutaFavorableTable {
        .grid-container {
          grid-template-columns: repeat(3, 1fr);

          .grid-item {
            font-size: 16px;
          }
        }

        .grid-container > div:not(:nth-child(6n + 1)) {
          border-left: none !important;
        }

        .grid-container > div:not(:nth-child(3n + 1)) {
          border-left: 3px solid white !important;
        }

        .grid-container > div:nth-child(n + 4):nth-child(-n + 12) {
          border-top: 3px solid white;
        }
      }
    }

    .whypeoContainer {
      height: 300px;

      .whypeobg {
        height: 100%;

        .whyPeoBox {
          width: 90%;

          h1 {
            font-size: 1.8rem;
          }

          p {
            font-size: 0.8rem;
            text-align: left;
            padding: 20px !important;
          }
        }
      }
    }

    .homeFooterContainer {
      background-color: #ffd251;
      padding-bottom: 25px;
      text-align: center;
      align-items: center;
      color: #6c757d;
      width: 100%;
      display: grid;

      .footer_partners_section {
        width: 100%;
        margin-top: 5px;

        .text {
          font-size: 10px;
        }

        .partnersLogo {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 30px;
          margin-top: -5px;

          img {
            max-width: 100px;
          }
        }
      }

      .footer_logo_section {
        width: 100%;

        .footer_logos {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 30px;

          img {
            max-width: 80px;
          }
        }
      }

      .footer_contact_section {
        display: flex;
        justify-content: center;
        align-items: center;

        //justify-content: space-around;
        padding-top: 0px;
        width: 96%;

        .vertical-divider {
          width: 1px;
          height: 50px;
          background-color: #6c757d;
          margin: 0 0px;
          z-index: 10;
        }

        .footerParts {
          display: flex;
          flex-direction: column;
          align-items: center;

          .footer_email {
            margin-bottom: 10px;

            p {
              margin: 0;
              font-size: 10px;

              &:first-of-type {
                margin-bottom: 5px;
                font-weight: bold;
              }

              b {
                font-size: 10px;
              }
            }

            a {
              display: inline-block;

              img {
                width: 30px;
                height: 30px;
              }
            }
          }

          .icons {
            display: flex;
            margin-top: -14px;
            justify-content: center;
            justify-content: space-between;
            justify-content: space-around;
          }

          .footer_icons {
            width: 73px;
            font-size: 10px;
          }
        }
      }
    }
  }
}

@media all and (min-width: 769px) and (max-width: 1024px) {
  .file-upload-label {
    cursor: pointer;
    padding: 5px 10px;
    background-color: white;
    color: black;
    border: 1px solid orange;
    border-radius: 6px;
    width: 30%;
    font-size: 12px;
  }
  .dropZonesContainer {
    .customDropZone,
    .customDropZone-uw {
      width: 276px;

      .dropzone {
        text-align: center;
        width: 300px;
        height: 40px;
        padding-top: 2px;
        border: 2px solid #faa558;
        background-color: #fafafa;
        border-radius: 10px;
        color: #8a5656;

        .dropZoneText {
          margin-top: 7px;
          cursor: pointer;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }

    .customDropZone-dashborad {
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;

      padding-inline: 5px;

      .customdropcontent {
        font-size: 15px;
      }

      .dropzone {
        text-align: center;
        width: 100%;
        height: 40px;
        padding-top: 2px;
        border: 2px solid #faa558;
        background-color: #fafafa;
        border-radius: 10px;
        color: #8a5656;

        .dropZoneText {
          margin-top: 7px;
          cursor: pointer;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }

    .customDropZone-uw {
      //height: 285px !important;
      margin-top: 50px;
      display: flex;
      align-items: center;

      .dropzone {
        height: 100px !important;
      }
    }

    .customDropZone-dashborad {
      height: 130px !important;
      display: flex;
      align-items: center;

      .dropzone {
        height: 100px !important;
      }
    }
  }
  .downloadbtnstyle {
    font-size: 15px;
    cursor: pointer;
    .downlaodnewlogo {
      width: 20px;
    }
  }
  .quote-table .bindquotebtnstyle .bindquotebtnstyletwo .bindquotebtn {
    width: 60%;
    height: 110px;
    margin: auto;
    border: 2px solid transparent;
    border-image: linear-gradient(to right, #13a0f9, #faa541);
    border-image-slice: 1;
    padding: 5px;
    display: flex;
    justify-content: center;
    border-radius: 1em;
    font-size: 17px;
  }
  .newquote-mainbody {
    .newquote-content {
      #locationPayrollContainer {
        .location-payroll-container {
          margin-left: 185px;
          margin-right: 20px;
          margin-bottom: 5px;
        }
        .question-list-container {
          margin-left: 20px;
          margin-right: 20px;
          margin-bottom: 5px;
          .paycycle-container {
            .requstedAdminRow {
              .requestedAdminContent {
                width: auto;
              }
            }
            .paycycle-style {
              margin-left: 8px;
              display: flex;
            }
          }
        }
      }
    }
  }
  @keyframes slideinquoteone {
    from {
      width: 105px;
      height: 105px;
      font-size: 12px !important;
      box-shadow: none;
    }

    to {
      width: 140px;
      height: 140px;
      font-size: 14px !important;
      box-shadow: -3px 3px 2px white, 3px 3px 2px white;
    }
  }

  @keyframes slideinquotecontainerone {
    from {
      width: 120px;
      height: 120px;
      font-size: 12px !important;
      box-shadow: none;
    }

    to {
      width: 160px;
      height: 160px;
      font-size: 17px !important;
      box-shadow: -2px 3px 1px white, 3px 3px 1px white;
    }
  }
  .external-box {
    width: 40%;
    margin: auto;
    .no-login-user-heading-check {
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      border: 1px solid #ddd;
      border-radius: 8px;
      background-color: #f9f9f9;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      .check-company-info {
        text-align: left;
        color: #427cbc;
        font-size: 15px;
        font-weight: bolder;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }

  .header-logo-style {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .hotscheduleslogo {
      .hotscheduleslogowidth {
        width: 250px;
        margin-left: -1rem;
      }
    }
    .affinitylogo {
      .affinitylogo1 {
        width: 250px;
      }
    }
  }
  .home-landing-page {
    .bringingcolumn {
      .logoreduce {
        margin-top: 130px;
        height: 130px !important;
      }

      .logoreducing {
        height: 145px !important;
        margin-top: 120px;
      }
    }

    .home-banner {
      // .banner-container {
      //   min-height: 100vh !important;
      //   height: 100vh !important;

      //   // .whitebox-banner {
      //   //   height:100vh !important;
      //   //   margin-top: 100vh !important;
      //   // }
      // }

      // .banner-content-container {
      //   height: auto !important;
      //   top: 12.5rem !important;

      //   .banner-form-container {
      //     width: 40% !important;
      //   }

      //   .banner-images-container {
      //     width: 65% !important;

      //     .banner-text-box {
      //       margin-top: 50px;

      //       .banner-stamps-text-container {
      //         padding-right: 5px !important;
      //         margin-top: 26px;

      //         .bannerStampText {
      //           font-size: 0.3rem;
      //           padding: 6px 5px 5px 9px !important;
      //         }
      //       }
      //     }

      //     h1 {
      //       font-size: 1.5rem;
      //     }

      //     h5 {
      //       font-size: 0.6rem;
      //     }

      //     .banner-people-image-box {
      //       margin-top: 34rem !important;

      //       .banner-two-people-image {
      //         height: 78% !important;
      //         width: 63% !important;
      //         top: 9rem;
      //         margin-top: -10rem !important;
      //         right: -8px !important;
      //       }
      //     }
      //   }
      // }
      .banner-container {
        .zonefirst {
          .headers-companies {
            margin-top: 10px;

            .header-sompany-logo-style {
              h1 {
                font-size: 3rem;
              }
            }

            .bannerStampText {
              h5 {
                font-size: 1.4rem;
              }
            }
          }

          .image-banner-style {
            .banner-two-people-image {
              width: 60%;
              height: 90%;
            }
          }
        }
      }

      .learn-more-about-us {
        width: 65%;
        margin: auto;

        .get-quote-now {
          height: 140px;
          width: 140px;
          bottom: 90% !important;
          left: 50% !important;
          // animation-name: slideinquotecontainerone;

          .get-quote-now-two {
            height: 120px;
            width: 120px;
            // animation-name: slideinquoteone;
          }

          .get-quote-now-text {
            font-size: 1rem;
          }
        }

        .form-col {
          width: 100%;

          // display: flex;
          // flex-direction: column;
          // justify-content: center;
          // align-items: center;
          input {
            padding: 10px 0 10px 6px;
            font-size: 11px !important;
            margin-top: 9px;
            margin-bottom: 10px;
          }

          input:nth-child(1) {
            margin-top: 6rem !important;
          }

          .btn-container {
            .btn {
              padding: 16px;
              width: 100%;
              font-size: 16px;
              font-weight: bold;
            }
          }
        }
      }

      .et_pb_bottom_inside_divider {
        top: 37.5rem !important;
      }
    }

    .our-services {
      .why-engage-section {
        .why-engage-container {
          margin-top: 5rem !important;

          .why-engage-container-box {
            width: 90% !important;
          }

          .why-engageImagebox {
            height: auto;

            .cardsContainer {
              width: 60%;
              height: auto;
              grid-template-columns: repeat(2, 1fr);
              gap: 6px;

              .card {
                font-size: 0.6rem !important;
                padding: 5px 5px 5px 5px !important;
                text-align: center;
              }
            }
          }
        }

        .whyEngageImage {
          margin-left: 4rem;
          height: 18rem;
        }

        .whyEngageText {
          font-size: 2rem;
        }
      }
    }

    .ratingContainer {
      margin-top: 5rem !important;

      div {
        width: 33.33%;

        div {
          width: 100%;

          hr {
            border-color: black;
            border-width: 4px;
          }
        }

        .fivestar_imgbox {
          width: 100%;
          position: relative;
          height: auto;
          border: 2px solid black;
          border-radius: 170px;
          text-align: center;

          p {
            font-size: 0.6rem !important;
          }

          .fivestarImage {
            width: 75% !important;
            height: 120% !important;
            position: absolute;
            top: -50px;
            /* Adjust this value to position the image as desired */
            left: 50%;
            transform: translateX(-50%);
          }

          p {
            margin-top: 0.9rem;
            margin-left: 20px;
            margin-right: 20px;
          }
        }
      }
    }

    .sutaFavouritesContainer {
      .sutaFavorableTable {
        width: 100%;
        height: 80%;
        background-size: cover;
        background-position: center;
        display: flex;
        align-items: left;
        justify-content: center;
        padding: 50px 0 50px 0;

        .grid-container {
          width: 95%;
          height: 60%;
          display: grid;
          grid-template-columns: repeat(6, 1fr);

          .grid-item {
            padding: 10px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 16px;
          }
        }

        .grid-container > div:not(:nth-child(6n + 1)) {
          border-left: 3px solid white;
        }

        .grid-container > div:nth-child(n + 7):nth-child(-n + 12) {
          border-top: 3px solid white;
        }
      }
    }

    .homeFooterContainer {
      background-color: #ffd251;
      padding-bottom: 25px;
      text-align: center;
      align-items: center;
      color: #6c757d;
      width: 100%;
      display: flex;

      .footer_partners_section {
        width: 100%;
        margin-top: 5px;

        .text {
          font-size: 10px;
        }

        .partnersLogo {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 30px;
          margin-top: -5px;

          img {
            max-width: 100px;
          }
        }
      }

      .footer_logo_section {
        width: 100%;

        .footer_logos {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 30px;

          img {
            max-width: 80px;
          }
        }
      }

      .footer_contact_section {
        display: flex;
        justify-content: center;
        align-items: center;

        //justify-content: space-around;
        padding-top: 17px;
        width: 96%;

        .vertical-divider {
          width: 1px;
          height: 50px;
          background-color: #6c757d;
          margin: 0 0px;
          z-index: 10;
        }

        .footerParts {
          display: flex;
          flex-direction: column;
          align-items: center;

          .footer_email {
            margin-bottom: 10px;

            p {
              margin: 0;
              font-size: 10px;

              &:first-of-type {
                margin-bottom: 5px;
                font-weight: bold;
              }

              b {
                font-size: 10px;
              }
            }

            a {
              display: inline-block;

              img {
                width: 30px;
                height: 30px;
              }
            }
          }

          .icons {
            display: flex;
            margin-top: -14px;
            justify-content: center;
            justify-content: space-between;
            justify-content: space-around;
          }

          .footer_icons {
            width: 73px;
            font-size: 10px;
          }
        }
      }
    }
  }
}

@media all and (min-width: 1025px) and (max-width: 1280px) {
  .file-upload-label {
    cursor: pointer;
    padding: 5px 10px;
    background-color: white;
    color: black;
    border: 1px solid orange;
    border-radius: 6px;
    width: 20%;
    font-size: 12px;
  }
  .dropZonesContainer {
    .customDropZone,
    .customDropZone-uw {
      width: 276px;

      .dropzone {
        text-align: center;
        width: 300px;
        height: 40px;
        padding-top: 2px;
        border: 2px solid #faa558;
        background-color: #fafafa;
        border-radius: 10px;
        color: #8a5656;

        .dropZoneText {
          margin-top: 7px;
          cursor: pointer;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }

    .customDropZone-dashborad {
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;

      padding-inline: 5px;

      .customdropcontent {
        font-size: 15px;
      }

      .dropzone {
        text-align: center;
        width: 100%;
        height: 40px;
        padding-top: 2px;
        border: 2px solid #faa558;
        background-color: #fafafa;
        border-radius: 10px;
        color: #8a5656;

        .dropZoneText {
          margin-top: 7px;
          cursor: pointer;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }

    .customDropZone-uw {
      //height: 285px !important;
      margin-top: 50px;
      display: flex;
      align-items: center;

      .dropzone {
        height: 100px !important;
      }
    }

    .customDropZone-dashborad {
      height: 130px !important;
      display: flex;
      align-items: center;

      .dropzone {
        height: 100px !important;
      }
    }
  }
  .downloadbtnstyle {
    font-size: 15px;
    cursor: pointer;
    .downlaodnewlogo {
      width: 20px;
    }
  }
  .quote-table .bindquotebtnstyle .bindquotebtnstyletwo .bindquotebtn {
    width: 60%;
    height: 110px;
    margin: auto;
    border: 2px solid transparent;
    border-image: linear-gradient(to right, #13a0f9, #faa541);
    border-image-slice: 1;
    padding: 5px;
    display: flex;
    justify-content: center;
    border-radius: 1em;
    font-size: 17px;
  }
  .external-box {
    width: 40%;
    margin: auto;
    .no-login-user-heading-check {
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      border: 1px solid #ddd;
      border-radius: 8px;
      background-color: #f9f9f9;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      .check-company-info {
        text-align: left;
        color: #427cbc;
        font-size: 15px;
        font-weight: bolder;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
  .home-landing-page .home-banner .banner-container .white-box {
    position: absolute;
    background: white;
    z-index: 0;
    top: 297px;
    height: 240px;
    width: 100%;
  }

  .contact-form-client {
    width: 32%;
    min-width: 32%;
  }

  .home-landing-page {
    .bringingcolumn {
      .logoreduce {
        margin-top: 130px;
        height: 145px !important;
      }

      .logoreducing {
        height: 160px !important;
        margin-top: 120px;
      }
    }

    .home-banner {
      // .banner-container {
      //   min-height: 100vh !important;
      //   height: 100vh !important;

      //   // .whitebox-banner {
      //   //   height: 175px !important;
      //   //   margin-top: 9rem !important;
      //   // }
      // }

      // .banner-content-container {
      //   height: auto !important;
      //   top: 12.5rem !important;

      //   .banner-form-container {
      //     width: 40% !important;
      //   }

      //   .banner-images-container {
      //     width: 65% !important;

      //     .banner-text-box {
      //       margin-top: 50px;

      //       .banner-stamps-text-container {
      //         padding-right: 5px !important;
      //         margin-top: 30px;

      //         .bannerStampText {
      //           font-size: 0.3rem;
      //           padding: 6px 5px 5px 9px !important;
      //         }
      //       }
      //     }

      //     h1 {
      //       font-size: 2rem;
      //     }

      //     h5 {
      //       font-size: 0.9rem;
      //     }

      //     .banner-people-image-box {
      //       margin-top: 34rem !important;

      //       .banner-two-people-image {
      //         height: 95% !important;
      //         width: 60% !important;
      //         top: 9rem;
      //         margin-top: -10rem !important;
      //         right: 10px !important;
      //       }
      //     }
      //   }
      // }
      .banner-container {
        .white-box {
          height: 190px;
        }

        .zonefirst {
          .headers-companies {
            margin-top: 30px;

            .header-sompany-logo-style {
              width: 100%;

              h1 {
                font-size: 2rem;
              }

              h5 {
                font-size: 1rem;
              }
            }

            .bannerStampText {
              h5 {
                font-size: 1rem;
              }
            }

            .image-background-banner {
              margin-top: 15px;
            }
          }

          .image-banner-style {
            margin-top: -60px;

            .banner-two-people-image {
              width: 130%;
              margin-left: -60px;
            }
          }
        }
      }

      .learn-more-about-us {
        width: 100%;
        top: 5rem;

        .get-quote-now {
          height: 140px;
          width: 140px;
          bottom: 85% !important;
          left: 50% !important;

          .get-quote-now-two {
            height: 120px;
            width: 120px;
          }

          .get-quote-now-text {
            font-size: 1.2rem;
          }
        }

        .form-col {
          width: 100%;

          // display: flex;
          // flex-direction: column;
          // justify-content: center;
          // align-items: center;
          input {
            padding: 7px 0 7px 16px;
            font-size: 14px !important;
            margin-top: 6px;
            margin-bottom: 10px;
          }

          input:nth-child(1) {
            margin-top: 5rem !important;
          }

          .btn-container {
            margin-top: 7% !important;
            margin-bottom: 7% !important;

            .btn {
              padding: 8px;
              width: 100%;
              font-size: 16px;
              font-weight: bold;
            }
          }
        }
      }

      .et_pb_bottom_inside_divider {
        top: 45rem !important;
      }
    }

    .our-services {
      .why-engage-section {
        .why-engage-container {
          margin-top: 5rem !important;

          .why-engage-container-box {
            width: 80% !important;
          }

          .why-engageImagebox {
            height: auto;

            .cardsContainer {
              width: 60%;
              height: auto;
              grid-template-columns: repeat(2, 1fr);
              gap: 6px;

              .card {
                font-size: 0.8rem !important;
                padding: 5px 5px 5px 5px !important;
                text-align: center;
              }
            }
          }
        }

        .whyEngageImage {
          margin-left: 4rem;
          height: 25rem;
        }

        .whyEngageText {
          h1 {
            font-size: 2rem !important;
          }

          margin-bottom: 14rem;
        }
      }
    }

    .ratingContainer {
      margin-top: 6rem !important;

      div:nth-child(1),
      div:nth-child(3) {
        width: 35%;
      }

      div:nth-child(2) {
        width: 60%;

        .fivestar_imgbox {
          width: 100%;
        }
      }

      .fivestar_imgbox {
        width: 100%;
        position: relative;
        height: auto;
        border: 2px solid black;
        border-radius: 170px;
        text-align: center;

        p {
          font-size: 1rem !important;
        }

        .fivestarImage {
          width: 60% !important;
          height: 120% !important;
          position: absolute;
          top: -80px !important;
          /* Adjust this value to position the image as desired */
          left: 50%;
          transform: translateX(-50%);
        }

        p {
          margin-top: 0.9rem;
          margin-left: 20px;
          margin-right: 20px;
        }
      }
    }

    .homeFooterContainer {
      background-color: #ffd251;
      padding-bottom: 25px;
      text-align: center;
      align-items: center;
      color: #6c757d;
      width: 100%;
      display: flex;

      .footer_partners_section {
        width: 100%;
        margin-top: 25px;

        .partnersLogo {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 30px;

          img {
            max-width: 100px;
          }
        }
      }

      .footer_logo_section {
        width: 100%;

        .footer_logos {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 30px;

          img {
            max-width: 100px;
          }
        }
      }

      .footer_contact_section {
        display: flex;
        justify-content: center;
        align-items: center;

        //justify-content: space-around;
        padding-top: 20px;
        width: 100%;

        .vertical-divider {
          width: 1px;
          height: 80px;
          background-color: #6c757d;
          margin: 0 20px;
          z-index: 10;
        }

        .footerParts {
          padding: 0 20px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .footer_email {
            margin-bottom: 10px;

            p {
              margin: 0;
              font-size: 16px;

              &:first-of-type {
                margin-bottom: 5px;
                font-weight: bold;
              }
            }

            a {
              display: inline-block;

              img {
                width: 30px;
                height: 30px;
              }
            }
          }

          .icons {
            display: flex;
            justify-content: center;
            justify-content: space-between;
            justify-content: space-around;
          }

          .footer_icons {
            width: 120px;
          }
        }
      }
    }
  }
}

@media all and (min-width: 1281px) and (max-width: 1536px) {
  .dropZonesContainer {
    .customDropZone,
    .customDropZone-uw {
      width: 276px;

      .dropzone {
        text-align: center;
        width: 300px;
        height: 40px;
        padding-top: 2px;
        border: 2px solid #faa558;
        background-color: #fafafa;
        border-radius: 10px;
        color: #8a5656;

        .dropZoneText {
          margin-top: 7px;
          cursor: pointer;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }

    .customDropZone-dashborad {
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;

      padding-inline: 5px;

      .customdropcontent {
        font-size: 15px;
      }

      .dropzone {
        text-align: center;
        width: 100%;
        height: 40px;
        padding-top: 2px;
        border: 2px solid #faa558;
        background-color: #fafafa;
        border-radius: 10px;
        color: #8a5656;

        .dropZoneText {
          margin-top: 7px;
          cursor: pointer;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }

    .customDropZone-uw {
      //height: 285px !important;
      margin-top: 50px;
      display: flex;
      align-items: center;

      .dropzone {
        height: 100px !important;
      }
    }

    .customDropZone-dashborad {
      height: 130px !important;
      display: flex;
      align-items: center;

      .dropzone {
        height: 100px !important;
      }
    }
  }
  .external-box {
    width: 40%;
    margin: auto;
    .no-login-user-heading-check {
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      border: 1px solid #ddd;
      border-radius: 8px;
      background-color: #f9f9f9;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      .check-company-info {
        text-align: left;
        color: #427cbc;
        font-size: 15px;
        font-weight: bolder;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
  .home-landing-page .home-banner .banner-container .white-box {
    position: absolute;
    background: white;
    z-index: 0;
    top: 321px;
    height: 215px;
    width: 100%;
  }

  .home-landing-page {
    .bringingcolumn {
      .logoreduce {
        margin-top: 130px;
        height: 155px !important;
      }

      .logoreducing {
        height: 170px !important;
        margin-top: 120px;
      }
    }

    .home-banner {
      .banner-container {
        .white-box {
          height: 210px;
        }

        .zonefirst {
          margin-top: 40px;

          .headers-companies {
            margin-top: 30px;

            .header-sompany-logo-style {
              width: 100%;

              h1 {
                font-size: 2rem;
              }

              h5 {
                font-size: 1rem;
              }
            }

            .bannerStampText {
              h5 {
                font-size: 1rem;
              }
            }

            .image-background-banner {
              margin-top: 20px;
            }
          }

          .image-banner-style {
            margin-top: -60px;

            .banner-two-people-image {
              width: 125%;
              margin-left: -60px;
            }
          }
        }
      }

      .learn-more-about-us {
        .form-col {
          padding-right: 30px;
        }
      }
    }
  }

  .ratingContainer {
    margin-top: 6rem !important;

    .fivestar_imgbox {
      width: 100%;
      position: relative;
      height: auto;
      border: 2px solid black;
      border-radius: 170px;
      text-align: center;

      p {
        font-size: 1.1rem !important;
      }

      .fivestarImage {
        width: 70% !important;
        height: 120% !important;
        position: absolute;
        top: -85px !important;
        /* Adjust this value to position the image as desired */
        left: 50%;
        transform: translateX(-50%);
      }

      p {
        margin-top: 0.9rem;
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }

  .why-engage-container {
    .why-engage-container-box {
      width: 98% !important;
    }
  }

  .two-people-image {
    .bannerPeople {
      margin-left: 10%;
    }

    img {
      width: 80%;
    }
  }

  .home-banner {
    // .whitebox-banner {
    //   height: 185px !important;
    //   margin-top: 13.5rem !important;
    // }

    // .banner-content-container {
    //   .banner-images-container {
    //     .banner-stamps-text-container {
    //       padding-right: 5px !important;
    //       margin-top: 2rem;

    //       .bannerStampText {
    //         h5 {
    //           font-size: 1rem !important;
    //         }
    //       }
    //     }
    //   }

    //   .banner-text-box {
    //     h5 {
    //       font-size: 1rem !important;
    //     }
    //   }
    // }

    .learn-more-about-us {
      width: 100%;

      .get-quote-now {
        height: 150px;
        width: 150px;
        bottom: 90% !important;
        left: 48% !important;

        .get-quote-now-two {
          height: 135px;
          width: 135px;
        }

        .get-quote-now-text {
          font-size: 1.1rem;
        }
      }

      .form-col {
        width: 100%;

        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
        input {
          margin-left: 10%;
        }
      }
    }

    .et_pb_bottom_inside_divider {
      top: 58rem !important;
    }
  }

  .our-services {
    .why-engage-section {
      .why-engage-container {
        margin-top: 5rem !important;

        .why-engage-container-box {
          width: 80% !important;
        }

        .why-engageImagebox {
          height: auto;

          .cardsContainer {
            width: 60%;
            height: auto;
            grid-template-columns: repeat(2, 1fr);
            gap: 6px;

            .card {
              font-size: 1rem !important;
              padding: 5px 5px 5px 5px !important;
              text-align: center;
            }
          }
        }
      }

      .whyEngageImage {
        margin-left: 4rem;
        height: 15rem;
      }

      .whyEngageText {
        font-size: 2rem;
      }
    }
  }
}

@media all and (min-width: 1537px) and (max-width: 1750px) {
  .dropZonesContainer {
    .customDropZone,
    .customDropZone-uw {
      width: 276px;

      .dropzone {
        text-align: center;
        width: 300px;
        height: 40px;
        padding-top: 2px;
        border: 2px solid #faa558;
        background-color: #fafafa;
        border-radius: 10px;
        color: #8a5656;

        .dropZoneText {
          margin-top: 7px;
          cursor: pointer;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }

    .customDropZone-dashborad {
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;

      padding-inline: 5px;

      .customdropcontent {
        font-size: 15px;
      }

      .dropzone {
        text-align: center;
        width: 100%;
        height: 40px;
        padding-top: 2px;
        border: 2px solid #faa558;
        background-color: #fafafa;
        border-radius: 10px;
        color: #8a5656;

        .dropZoneText {
          margin-top: 7px;
          cursor: pointer;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }

    .customDropZone-uw {
      //height: 285px !important;
      margin-top: 50px;
      display: flex;
      align-items: center;

      .dropzone {
        height: 100px !important;
      }
    }

    .customDropZone-dashborad {
      height: 130px !important;
      display: flex;
      align-items: center;

      .dropzone {
        height: 100px !important;
      }
    }
  }
  .home-landing-page .home-banner .banner-container .white-box {
    position: absolute;
    background: white;
    z-index: 0;
    top: 335px;
    height: 240px;
    width: 100%;
  }

  .home-banner {
    .banner-container {
      .white-box {
        height: 250px;
      }

      .zonefirst {
        margin-top: 40px;

        .headers-companies {
          margin-top: 30px;

          .header-sompany-logo-style {
            width: 100%;

            h1 {
              font-size: 2rem;
            }

            h5 {
              font-size: 1rem;
            }
          }

          .bannerStampText {
            h5 {
              font-size: 1rem;
            }
          }

          .image-background-banner {
            margin-top: 75px;
          }
        }

        .image-banner-style {
          margin-top: -60px;

          .banner-two-people-image {
            width: 125%;
            margin-left: -60px;
          }
        }
      }
    }

    .learn-more-about-us {
      .form-col {
        padding-right: 30px;
      }
    }
  }

  @keyframes slideinquotetwo {
    from {
      width: 135px;
      height: 135px;
      font-size: 12px !important;
      box-shadow: none;
    }

    to {
      width: 170px;
      height: 170px;
      font-size: 14px !important;
      box-shadow: -3px 3px 2px white, 3px 3px 2px white;
    }
  }

  @keyframes slideinquotecontainertwo {
    from {
      width: 150px;
      height: 150px;
      font-size: 12px !important;
      box-shadow: none;
    }

    to {
      width: 190px;
      height: 190px;
      font-size: 17px !important;
      box-shadow: -2px 3px 1px white, 3px 3px 1px white;
    }
  }

  .home-landing-page {
    .bringingcolumn {
      .logoreduce {
        margin-top: 130px;
        height: 170px !important;
      }

      .logoreducing {
        height: 185px !important;
        margin-top: 120px;
      }
    }
  }

  .home-banner {
    .whitebox-banner {
      height: 230px !important;
      margin-top: 15rem !important;
    }

    .banner-form-container {
      width: 30% !important;
    }

    // .banner-images-container {
    //   width: 70% !important;
    // }

    .banner-stamps-text-container {
      margin-top: 2rem;

      .bannerStampText {
        h5 {
          font-size: 1.2rem !important;
        }
      }
    }

    .banner-two-people-image {
      width: 39rem;
      right: 5px !important;
    }

    .learn-more-about-us {
      width: 90%;
      top: 3rem;

      .get-quote-now {
        height: 185px;
        width: 185px;
        bottom: 85% !important;
        left: 48% !important;
        // animation-name: slideinquotecontainertwo;

        .get-quote-now-two {
          height: 170px;
          width: 170px;
          // animation-name: slideinquotetwo;
        }

        .get-quote-now-text {
          font-size: 1.7rem;
          line-height: 40px !important;
        }
      }

      .form-col {
        width: 100%;

        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
        input {
          padding: 10px 0 10px 26px;
          font-size: 1rem !important;
          margin-top: 6px;
          margin-bottom: 10px;
        }

        // input:nth-child(1) {
        //   margin-top: 5rem !important;
        // }
        .btn-container {
          margin-top: 7% !important;
          margin-bottom: 7% !important;

          .btn {
            padding: 8px;
            width: 100%;
            font-size: 16px;
          }
        }
      }
    }

    .et_pb_bottom_inside_divider {
      top: 55.5rem !important;
    }
  }

  .our-services {
    .why-engage-section {
      .why-engage-container {
        margin-top: 5rem !important;

        .why-engage-container-box {
          width: 80% !important;
        }

        .why-engageImagebox {
          height: auto;

          .cardsContainer {
            width: 60%;
            height: auto;
            grid-template-columns: repeat(2, 1fr);
            gap: 6px;

            .card {
              font-size: 1.2rem !important;
              padding: 5px 5px 5px 5px !important;
              text-align: center;
            }
          }
        }
      }

      .whyEngageImage {
        margin-left: 4rem;
        height: 15rem;
      }

      .whyEngageText {
        font-size: 2rem;
      }
    }
  }

  .ratingContainer {
    margin-top: 8rem !important;

    .fivestar_imgbox {
      width: 100%;
      position: relative;
      height: auto;
      border: 2px solid black;
      border-radius: 170px;
      text-align: center;

      p {
        font-size: 1.4rem !important;
      }

      .fivestarImage {
        width: 65% !important;
        height: 120% !important;
        position: absolute;
        top: -100px !important;
        /* Adjust this value to position the image as desired */
        left: 50%;
        transform: translateX(-50%);
      }

      p {
        margin-top: 0.9rem;
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }
}

@media all and (min-width: 1751px) and (max-width: 1920px) {
  .dropZonesContainer {
    .customDropZone,
    .customDropZone-uw {
      width: 276px;

      .dropzone {
        text-align: center;
        width: 300px;
        height: 40px;
        padding-top: 2px;
        border: 2px solid #faa558;
        background-color: #fafafa;
        border-radius: 10px;
        color: #8a5656;

        .dropZoneText {
          margin-top: 7px;
          cursor: pointer;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }

    .customDropZone-dashborad {
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;

      padding-inline: 5px;

      .customdropcontent {
        font-size: 15px;
      }

      .dropzone {
        text-align: center;
        width: 100%;
        height: 40px;
        padding-top: 2px;
        border: 2px solid #faa558;
        background-color: #fafafa;
        border-radius: 10px;
        color: #8a5656;

        .dropZoneText {
          margin-top: 7px;
          cursor: pointer;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }

    .customDropZone-uw {
      //height: 285px !important;
      margin-top: 50px;
      display: flex;
      align-items: center;

      .dropzone {
        height: 100px !important;
      }
    }

    .customDropZone-dashborad {
      height: 130px !important;
      display: flex;
      align-items: center;

      .dropzone {
        height: 100px !important;
      }
    }
  }
  .home-landing-page .home-banner .banner-container .white-box {
    position: absolute;
    background: white;
    z-index: 0;
    top: 335px;
    height: 240px;
    width: 100%;
  }

  .home-landing-page {
    .home-banner {
      .banner-container {
        .white-box {
          height: 250px;
        }

        .zonefirst {
          margin-top: 40px;

          .headers-companies {
            margin-top: 30px;

            .header-sompany-logo-style {
              width: 100%;

              h1 {
                font-size: 2rem;
              }

              h5 {
                font-size: 1rem;
              }
            }

            .bannerStampText {
              h5 {
                font-size: 1rem;
              }
            }

            .image-background-banner {
              margin-top: 93px;
            }
          }

          .image-banner-style {
            margin-top: -60px;

            .banner-two-people-image {
              width: 125%;
              margin-left: -60px;
            }
          }
        }
      }

      .learn-more-about-us {
        .form-col {
          padding-right: 30px;
        }
      }
    }

    .top-image {
      .first-row {
        padding-top: 130px;
      }
    }

    .learn-more-about-us {
      .get-quote-now {
        height: 200px;
        width: 200px;
        border-radius: 50%;
        background-color: white;
        position: absolute;
        bottom: 87%;
        right: 21%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        animation-duration: 1s;
        // animation-name: slideinquotecontainer;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;
        box-shadow: none;
      }

      .form-col {
        width: 103%;
      }
    }
  }

  // .banner-content-container {
  //   .banner-images-container {
  //     .banner-stamps-text-container {
  //       padding-right: 5px !important;
  //       margin-top: 2rem;

  //       .bannerStampText {
  //         h5 {
  //           font-size: 1.2rem !important;
  //         }
  //       }
  //     }
  //   }

  //   .banner-text-box {
  //     h1 {
  //       font-size: 3rem;
  //     }

  //     h5 {
  //       font-size: 1.2rem !important;
  //     }
  //   }
  // }

  .banner-two-people-image {
    width: 39rem !important;
    right: 20px !important;
  }
}

@media all and (min-width: 1921px) and (max-width: 3840px) {
  .dropZonesContainer {
    .customDropZone,
    .customDropZone-uw {
      width: 276px;

      .dropzone {
        text-align: center;
        width: 300px;
        height: 40px;
        padding-top: 2px;
        border: 2px solid #faa558;
        background-color: #fafafa;
        border-radius: 10px;
        color: #8a5656;

        .dropZoneText {
          margin-top: 7px;
          cursor: pointer;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }

    .customDropZone-dashborad {
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;

      padding-inline: 5px;

      .customdropcontent {
        font-size: 15px;
      }

      .dropzone {
        text-align: center;
        width: 100%;
        height: 40px;
        padding-top: 2px;
        border: 2px solid #faa558;
        background-color: #fafafa;
        border-radius: 10px;
        color: #8a5656;

        .dropZoneText {
          margin-top: 7px;
          cursor: pointer;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }

    .customDropZone-uw {
      //height: 285px !important;
      margin-top: 50px;
      display: flex;
      align-items: center;

      .dropzone {
        height: 100px !important;
      }
    }

    .customDropZone-dashborad {
      height: 130px !important;
      display: flex;
      align-items: center;

      .dropzone {
        height: 100px !important;
      }
    }
  }
  .home-landing-page .home-banner .banner-container .white-box {
    position: absolute;
    background: white;
    z-index: 0;
    top: 297px;
    height: 240px;
    width: 100%;
  }

  .home-landing-page {
    .home-banner {
      .banner-container {
        .white-box {
          height: 351px;
        }

        .zonefirst {
          margin-top: 40px;

          .headers-companies {
            margin-top: 30px;

            .header-sompany-logo-style {
              width: 100%;

              h1 {
                font-size: 2rem;
              }

              h5 {
                font-size: 1rem;
              }
            }

            .bannerStampText {
              h5 {
                font-size: 1rem;
              }
            }

            .image-background-banner {
              margin-top: 93px;
            }
          }

          .image-banner-style {
            margin-top: -60px;

            .banner-two-people-image {
              width: 125%;
              margin-left: -60px;
            }
          }
        }
      }

      .learn-more-about-us {
        .form-col {
          padding-right: 30px;
        }
      }
    }

    .top-image {
      .first-row {
        padding-top: 66px;
      }
    }

    .learn-more-about-us {
      .get-quote-now {
        height: 200px;
        width: 200px;
        border-radius: 50%;
        background-color: white;
        position: absolute;
        bottom: 87%;
        right: 30%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        animation-duration: 1s;
        // animation-name: slideinquotecontainer;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;
        box-shadow: none;
        font-size: 1.2rem;
      }

      .form-col {
        width: 103%;
      }
    }

    .bringingcolumn {
      .logoreduce {
        margin-top: 75px;
        height: 180px !important;
      }

      .logoreducing {
        height: 195px !important;
        margin-top: 65px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .home-landing-page .our-services .row-2 .col .card .card-body .card-text {
    font-size: 1rem !important;
  }

  .home-landing-page .row-1 .why-us-text {
    font-size: 2rem !important;
  }

  .row-1 {
    .our-services-text,
    .why-us-text,
    .learn-text {
      font-size: 2rem !important;
    }
  }
}

@media screen and (min-width: 1450px) {
  .home-landing-page .top-image .btn {
    margin-top: 1rem !important;
    height: 45px;
    font-size: 20px;
    width: 220px;
  }
}

@media screen and (max-width: 1450px) {
  .home-landing-page .top-image .btn {
    margin-top: -1rem !important;
  }
}

@media screen and (max-width: 1250px) {
  .home-landing-page .top-image .btn {
    margin-top: -2rem !important;
  }
}

@media screen and (max-width: 1250px) {
  .home-landing-page .top-image .btn {
    margin-top: -2rem !important;
  }
}

@media (max-width: 992px) {
  .pink-header1 .companyProfileheadertwo {
    display: none;
  }

  .pink-header1 .font-family-montserrat-bold {
    width: 100%;
    text-align: center;
    padding-left: 0px;
  }

  .pink-header .companyProfileheadertwo {
    display: none;
  }

  .pink-header .font-family-montserrat-bold {
    width: 100%;
    text-align: center;
    padding-left: 0px;
  }

  .headingboxstyle .companyProfileheadertwo {
    display: none;
  }

  .quote-table .headingboxstyle .font-family-montserrat-bold {
    text-align: center;
    padding-left: 0px;
    width: 100%;
    font: bold;
  }

  .quote-table .bindquotebtnstyle .bindquotebtnstyletwo {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
  }

  .quote-table .bindquotebtnstyle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .quote-table
    .bindquotebtnstyle
    .bindquotebtnstyletwo
    .bindquotebtn-disable
    button {
    width: 100%;
    font-size: 22px;
    background-color: #a3a2a0;
  }

  .dropZonesContainer .customDropZone {
    height: 220px;
    width: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
    padding-inline: 60px;
    justify-content: flex-end;

    .dropzone {
      width: 100%;
    }
  }
}

@media screen and (max-width: 666px) {
  .home-landing-page .top-image .btn {
    margin-top: 2rem !important;
  }

  .row-1 {
    .our-services-text,
    .why-us-text,
    .learn-text {
      font-size: 1.5rem !important;
    }
  }
}

@keyframes slidein {
  from {
    width: 96px;
    height: 96px;
    font-size: 12px;
    box-shadow: none;
  }

  to {
    width: 120px;
    height: 120px;
    font-size: 17px;
    box-shadow: 2px 2px 5px grey, -2px -2px 5px grey;
  }
}

@keyframes slideinquote {
  from {
    width: 115px;
    height: 115px;
    font-size: 12px !important;
    box-shadow: none;
  }

  to {
    width: 150px;
    height: 150px;
    font-size: 14px !important;
    box-shadow: -3px 3px 2px white, 3px 3px 2px white;
  }
}

@keyframes slideinquotecontainer {
  from {
    width: 130px;
    height: 130px;
    font-size: 12px !important;
    box-shadow: none;
  }

  to {
    width: 170px;
    height: 170px;
    font-size: 17px !important;
    box-shadow: -2px 3px 1px white, 3px 3px 1px white;
  }
}

@media screen and (max-width: 550px) {
  [class*="bannerColumn"] {
    font-size: 15px !important;
  }

  [class*="boldText"] {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 400px) {
  [class*="bannerColumn"] {
    min-height: 400px !important;
  }

  [class*="boldText"] {
    font-size: 20px !important;
  }
}

.MuiTableSortLabel-root {
  color: white !important;
}

// .MuiTableSortLabel-root:hover {
//   color: #ff9900 !important;
// }
.MuiTableSortLabel-root {
  color: black !important;
}

.MuiTableSortLabel-active {
  font-weight: 700 !important;
  // color: white !important;

  .MuiTableSortLabel-icon {
    // color: white !important;
  }
}

.navbarcoloring {
  background-color: #ffffff;
}

.navwidth {
  width: 100% !important;
}

@media only screen and (max-width: 992px) {
  #carrier,
  #Annaulprem,
  #claims,
  #totalincurred {
    display: inline-flex;
    max-width: 50%;
    flex-direction: column;
  }
}

#peo-header_in_pdf {
  color: #ff5a54 !important;
  font-size: 28px;

  font-weight: 600;
  margin-left: 400px !important;
  margin-bottom: 30px !important;
  margin-top: 27px !important;
  width: 410px !important;
}

//clientsuta
@media only screen and (min-width: 1600px) {
  #clientsuta {
    margin-top: 23px !important;
  }
}

@media only screen and (max-width: 1149px) and (min-width: 992px) {
  #clientsuta {
    margin-top: 9px !important;
  }
}

@media only screen and (min-width: 1150px) and (max-width: 1349px) {
  #clientsuta {
    margin-top: 23px !important;
  }
}

//requestedsuta
@media only screen and (max-width: 1349px) and (min-width: 992px) {
  #requestedsuta {
    margin-top: 9px !important;
  }
}

@media only screen and (min-width: 2024px) {
  #requestedsuta {
    margin-top: 26px !important;
  }
}

//requestedWc
@media only screen and (max-width: 1349px) and (min-width: 992px) {
  #requestedWc {
    margin-top: 9px !important;
  }
}

@media only screen and (min-width: 2045px) {
  #requestedWc {
    margin-top: 23px !important;
  }
}

@media only screen and (max-width: 776px) {
  #dateRate1 {
    // padding-left: 107px !important;
    display: flex;
    justify-content: center;
  }

  .quotePageTableContent {
    font-size: 0.6rem !important;
  }

  .BindableQuoteContent {
    font-size: 0.9rem !important;
  }

  .Whyus__coloumn {
    flex-direction: column !important;
  }
}

@media only screen and (max-width: 550px) {
  .export-btn {
    font-size: 0.69rem !important;
  }

  .peo-btn,
  .paygo-btn {
    font-size: 0.9rem !important;
  }
}

@media only screen and (max-width: 976px) {
  .locationbreakcss {
    width: 100% !important;
    justify-content: left !important;
  }
}

@media only screen and (max-width: 700px) {
  .affinitylogo {
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    // margin-top: 25px;
    margin-right: 5px;
    height: 80px;
  }
}

@media only screen and (max-width: 491px) {
  .affinitylogo {
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    //  margin-top: 25px;
    height: 45px;
  }
}

@media only screen and (max-width: 700px) {
  .hotscheduleslogo {
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

// @media only screen and (max-width: 200px) {
//   .hotscheduleslogowidth {
//     width: 100% !important;
//     justify-content: center !important;
//     align-items: center !important;
//     padding: 20px 37px 0px 35px !important;
//     height: 100% !important;
//   }
// }

@media only screen and (max-width: 700px) {
  .question-list-container {
    width: auto !important;
  }
}

@media only screen and (max-width: 700px) {
  .paygoandpeo {
    // padding-right: 50px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1400px) {
  .logoreduce1 {
    height: 80px !important;
  }

  .logo-1 {
    width: 20% !important;
  }

  .logoreduce {
    height: 155px;
  }
}

@media only screen and (min-width: 1700px) {
  .logoreduce1 {
    height: 100px !important;
  }

  .logo-1 {
    width: 17% !important;
  }

  .logoreduce {
    height: 135px;
  }

  .logo-2 {
    width: 25% !important;
  }
}

@media only screen and (min-width: 1800px) {
  .logoreduce1 {
    height: 110px !important;
  }

  .logo-1 {
    width: 15% !important;
  }

  .logo-2 {
    width: 20% !important;
  }
}

@media only screen and (max-width: 1400px) {
  .logoreduce1 {
    height: 80px !important;
  }

  .logoreduce {
    height: 120px;
  }
}

@media only screen and (max-width: 1320px) {
  // .logoreduce1 {
  //   height: 70px !important;
  // }
  .logo-1 {
    width: 24% !important;
  }
}

@media only screen and (max-width: 1261px) {
  .logoreduce1 {
    height: 65px !important;
  }

  .logo-1 {
    width: 24% !important;
  }
}

@media only screen and (max-width: 1210px) {
  .logoreduce1 {
    height: 60px !important;
  }

  .logo-1 {
    width: 26% !important;
  }
}

@media only screen and (max-width: 1000px) {
  .logo-1 {
    width: 30% !important;
  }

  .logoreduce {
    height: 110px;
  }
}

@media only screen and (max-width: 850px) {
  .logo-1 {
    width: 36% !important;
  }
}

@media only screen and (min-width: 775px) {
  .bringingcolumn {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
}

@media only screen and (max-width: 775px) {
  .logo-1 {
    width: 38% !important;
  }
}

@media only screen and (max-width: 720px) {
  .logoreduce {
    height: 100px;
  }

  .logo-1 {
    width: 40% !important;
  }
}

@media only screen and (max-width: 630px) {
  .logoreduce1 {
    height: 65px !important;
  }

  .logo-1 {
    width: 45% !important;
  }
}

@media only screen and (max-width: 560px) {
  .logoreduce1 {
    height: 60px !important;
  }
}

@media only screen and (max-width: 520px) {
  .bringingcolumn {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
  }
}

.button_color {
  background-color: #faa541 !important;
}

@media only screen and (max-width: 992px) {
  .sourceDropDown {
    display: flex;
    flex-direction: column !important;
  }
}

.adminPercent-container {
  box-shadow: 0.5px 1px 4px grey;
  border: 0.3px gre solid;

  .adminPercent-sign {
    background: white;
    width: 27px;
    border-radius: 3px;
    /* height: 100% !important; */
    border-left: 0.3px grey solid;
    display: flex;
    align-items: center;
    /* box-shadow: 0.5px 1px 4px grey; */
    justify-content: center;
  }

  input {
    box-shadow: none !important;
  }
}

.model-download {
  width: 60%;
  height: 100%;
}

.select-type-salesforce-dashboard {
  height: 35px;
  color: black;
  padding-left: 5px;
  box-shadow: 0.5px 1px 4px grey;
  border: none;
  margin-bottom: 10px;

  option {
    padding: 10px;
  }
}

@media only screen and (max-width: 460px) {
  #formLogin {
    width: 100vw !important;
  }
}

nav {
  background-color: #91cfa1;
  height: 50px;
  //padding: 0 16px;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  /* fade in checked menu */
}

nav .m-menu__checkbox {
  display: none;
}

nav label.m-menu__toggle {
  cursor: pointer;
}

nav .m-menu {
  position: fixed;
  top: 0;
  left: 0;
  max-width: 360px;
  width: calc(100vw - 30px);
  // height: 100vh;
  height: 100%;
  // height: calc(var(--vh, 1vh) * 100);
  -moz-transform: translate3d(-450px, 0, 0);
  -o-transform: translate3d(-450px, 0, 0);
  -ms-transform: translate3d(-450px, 0, 0);
  -webkit-transform: translate3d(-450px, 0, 0);
  transform: translate3d(-450px, 0, 0);
  -moz-transition: transform 0.35s;
  -o-transition: transform 0.35s;
  -webkit-transition: transform 0.35s;
  transition: transform 0.35s;
  z-index: 12;
  overflow: hidden;
  background-color: #427cbc;
  margin-top: 60px;

  .nav-link {
    &.active {
      color: #ffc423;
      border: none;
    }
  }
}

nav .m-menu__overlay {
  background-color: rgba(103, 103, 103, 0.5);
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  z-index: 12;
  display: none;
}

nav .m-menu__header {
  padding-left: 0px;
  height: 0px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
}

nav .m-menu__header span {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

nav .m-menu .m-menu {
  -moz-transform: translate3d(480px, 0, 0);
  -o-transform: translate3d(480px, 0, 0);
  -ms-transform: translate3d(480px, 0, 0);
  -webkit-transform: translate3d(480px, 0, 0);
  transform: translate3d(480px, 0, 0);
}

// nav .m-menu ul {
//   height: 100%;
//   overflow-y: auto;
// }

// nav .m-menu ul li a,
// .m-menu ul li label {
//   display: block;
//   text-align: left;
//   padding: 0 15px;
//   line-height: 47px;
//   text-decoration: none;
//   color: #333;
//   cursor: pointer;
//   font-size: 1rem;
//   border-bottom: 1px solid #e8e8e8;
//   position: relative;
// }

// nav .m-menu ul li label.a-label__chevron::after {
//   content: "";
//   position: absolute;
//   display: inline-block;
//   height: 10px;
//   width: 10px;
//   border-color: #333;
//   border-style: solid;
//   border-width: 1px 1px 0 0;
//   -moz-transform: rotate(45deg);
//   -o-transform: rotate(45deg);
//   -ms-transform: rotate(45deg);
//   -webkit-transform: rotate(45deg);
//   transform: rotate(45deg);
//   top: 50%;
//   margin-top: -5px;
//   right: 16px;
// }

// nav .m-menu ul li .-invisible {
//   border-bottom: 0;
// }

nav .m-menu .m-menu label.m-menu__toggle {
  display: flex;
  border-bottom: 0;
  padding: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

nav .m-menu__checkbox:checked ~ .m-menu__overlay {
  display: block;
}

nav .m-menu__checkbox:checked ~ .m-menu {
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.m-menu__toggle {
  padding-left: 25px;
}

.peo-header {
  color: #ff5a54 !important;
  font-size: 28px;
  margin-right: 1.5%;
  margin-top: 1%;
  font-weight: 600;
}

.accord-toolbar {
  padding: 10px;
  display: flex;
}

.accord-container {
  margin-top: 10px;
  border: 1px solid #fff;
  box-shadow: 0.5px 1px 4px grey;
  background-color: #f1f1f1;
}

.start_new_quote_btn {
  color: #c0224d;
}

@media only screen and (max-width: 281px) {
  .home-landing-page .testimonial .row .large-col {
    padding: 33px !important;
  }
}

@media only screen and (max-width: 900px) {
  .Dashboard__menucontent {
    padding: 6px;
    padding-left: 4px;
  }
}

#quoteGenetatepage {
  background-color: #e5f3fe;
}

.Header_QuoteTable {
  color: white !important;
  background-color: #589aca;
  // text-align: left !important;
}

// #quoteGenerate tbody tr {
//   text-align: center !important;
// }

#quoteGenerate th {
  padding: 0px 0px 0px 2px !important;
  border: 0.5px solid #dee2e6;
}

#quoteGenerate td {
  padding: 6px !important;
  border: 0.5px solid #dee2e6;
}

#quoteGenerate tbody tr:nth-child(even) {
  background-color: #dbebbb;
}

#quoteGenerate tbody tr:nth-child(odd) {
  background-color: #ffffff;
}

#quoteGenerate tbody td {
  vertical-align: middle !important;
}

.ExporttoPdf_button {
  margin: auto;
  display: flex;
  background-color: #fba441;
  color: white;
  border-radius: 5px;
  font-size: 1.5rem;
  box-shadow: 2px 2px 5px rgba(236, 226, 226, 0.2);
}

.Submission-uw-dashboard {
  tbody tr:first-child td:first-child {
    background-image: url("../images/filter_excel.png");
    background-repeat: no-repeat;
    background-size: 70% 70%;
    top: 5px !important;
    left: 4px !important;
    position: relative !important;
  }
}

.sub_unberwritter_tab {
  width: 50% !important;
}

.pre-underwriter {
  tbody tr:first-child td:first-child {
    background-image: url("../images/filter_excel.png");
    background-repeat: no-repeat;
    background-size: 70% 70%;
    top: 5px !important;
    left: 4px !important;
    position: relative !important;
  }
}

.sub_unberwritter_tab tbody tr:first-child td:first-child {
  background-image: none;
  top: 0 !important;
  left: 4px !important;
  position: none !important;
}

#pre_campaigned_subtab tbody tr:first-child td:first-child {
  background-image: none;
  top: 0 !important;
  left: 4px !important;
  position: none !important;
}

#pre_engaged_subtab tbody tr:first-child td:first-child {
  background-image: none;
  top: 0 !important;
  left: 4px !important;
  position: none !important;
}

.sub_unberwritter_tab {
  font-size: 1rem !important;
  font-family: "Montserrat";
}

.sub_unberwritter_tab thead tr th {
  padding: 5px !important;
  font-family: "Montserrat";
}

.sub_unberwritter_tab tbody tr td {
  padding: 5px !important;
}

.sub_underwritter_pdfImage1,
.sub_underwritter_pdfImage2,
.sub_underwritter_pdfImage3 {
  width: 30px;
  height: 50px;
}

.sub_underwritter_pdfImage4 {
  width: 50px;
  height: 80px;
}

.sub_unberwritter_header {
  font-size: 0.9rem !important;
  font-family: "Montserrat";
}

.sub_underwritter_header_row1,
.sub_underwritter_header_row2,
.sub_underwritter_header_row3,
.sub_underwritter_header_row4,
.sub_underwritter_header_row5,
.sub_underwritter_rejected_button,
.sub_underwritter_hold_button,
.sub_underwritter_approved_button {
  font-size: 0.9rem !important;
  font-family: "Montserrat";
  text-align: center !important;
}

.sub_unberwritter_tab tbody tr td {
  text-align: center;
  font-size: 0.9rem !important;
  font-family: "Montserrat";
}

.sub_underwritter_approved_button,
.sub_underwritter_hold_button,
.sub_underwritter_rejected_button {
  color: #fff;
  border: none;
}

.sub_underwritter_approved_button {
  background-color: #008e1b;
}

.sub_underwritter_hold_button {
  background-color: #ffbd16;
}

.sub_underwritter_rejected_button {
  background-color: #cb2e3e;
}

// .sub_preunder_UW thead tr th,
// .sub_preunder_UW tbody tr td {
//   font-size: 1rem !important;
//   font-family: "Montserrat";
//   text-align: center !important;
// }

@media only screen and (max-width: 1600px) {
  .sub_underwritter_header_row1,
  .sub_underwritter_header_row2,
  .sub_underwritter_header_row3,
  .sub_underwritter_header_row4,
  .sub_underwritter_header_row5,
  .sub_underwritter_rejected_button,
  .sub_underwritter_hold_button,
  .sub_underwritter_approved_button {
    font-size: 0.87rem !important;
    font-family: "Montserrat";
    text-align: center !important;
  }

  .sub_underwritter_pdfImage1,
  .sub_underwritter_pdfImage2,
  .sub_underwritter_pdfImage3 {
    width: 25px;
    height: 45px;
  }

  .sub_underwritter_pdfImage4 {
    width: 45px;
    height: 70px;
  }

  // .sub_preunder_UW thead tr th,
  // .sub_preunder_UW tbody tr td {
  //   font-size: 0.9rem !important;
  //   font-family: "Montserrat";
  //   text-align: center !important;
  // }
}

@media only screen and (max-width: 1500px) {
  .sub_underwritter_header_row1,
  .sub_underwritter_header_row2,
  .sub_underwritter_header_row3,
  .sub_underwritter_header_row4,
  .sub_underwritter_header_row5,
  .sub_underwritter_rejected_button,
  .sub_underwritter_hold_button,
  .sub_underwritter_approved_button {
    font-size: 0.85rem !important;
    font-family: "Montserrat";
    text-align: center !important;
  }

  .sub_underwritter_pdfImage1,
  .sub_underwritter_pdfImage2,
  .sub_underwritter_pdfImage3 {
    width: 22px;
    height: 41px;
  }

  .sub_underwritter_pdfImage4 {
    width: 40px;
    height: 65px;
  }

  // .sub_preunder_UW thead tr th,
  // .sub_preunder_UW tbody tr td {
  //   font-size: 0.85rem !important;
  //   font-family: "Montserrat";
  //   text-align: center !important;
  // }
}

.Submission-uw-dashboard {
  font-family: "Montserrat";
}

@media only screen and (max-width: 1410px) {
  .sub_underwritter_header_row1,
  .sub_underwritter_header_row2,
  .sub_underwritter_header_row3,
  .sub_underwritter_header_row4,
  .sub_underwritter_header_row5,
  .sub_underwritter_rejected_button,
  .sub_underwritter_hold_button,
  .sub_underwritter_approved_button {
    font-size: 0.8rem !important;
    font-family: "Montserrat";
    text-align: center !important;
  }

  .sub_underwritter_pdfImage1,
  .sub_underwritter_pdfImage2,
  .sub_underwritter_pdfImage3 {
    width: 20px;
    height: 37px;
  }

  .sub_underwritter_pdfImage4 {
    width: 37px;
    height: 30px;
  }

  // .sub_preunder_UW thead tr th,
  // .sub_preunder_UW tbody tr td {
  //   font-size: 0.8rem !important;
  //   font-family: "Montserrat";
  //   text-align: center !important;
  // }
}

@media only screen and (max-width: 1300px) {
  .sub_underwritter_header_row1,
  .sub_underwritter_header_row2,
  .sub_underwritter_header_row3,
  .sub_underwritter_header_row4,
  .sub_underwritter_header_row5,
  .sub_underwritter_rejected_button,
  .sub_underwritter_hold_button,
  .sub_underwritter_approved_button {
    font-size: 0.72rem !important;
    font-family: "Montserrat";
    text-align: center !important;
  }

  // .sub_preunder_UW thead tr th,
  // .sub_preunder_UW tbody tr td {
  //   font-size: 0.72rem !important;
  //   font-family: "Montserrat";
  //   text-align: center !important;
  // }
  .sub_underwritter_pdfImage1,
  .sub_underwritter_pdfImage2,
  .sub_underwritter_pdfImage3 {
    width: 18px;
    height: 34px;
  }

  .sub_underwritter_pdfImage4 {
    width: 35px;
    height: 27px;
  }
}

.salesPersonTab,
.sub_tab1,
.sub_tab2,
.sub_tab3,
.sub_tab4 {
  width: 50% !important;
}

.salesPersonTab tbody tr td,
.sub_tab1 tbody tr td,
.sub_tab2 tbody tr td,
.sub_tab3 tbody tr td,
.sub_tab4 tbody tr td {
  text-align: center;
  font-size: 0.9rem !important;
  font-family: "Montserrat";
  width: 20% !important;
  white-space: nowrap;
}

.salesPersonTab thead tr td,
.sub_tab1 thead tr th,
.sub_tab2 thead tr th,
.sub_tab3 thead tr th,
.sub_tab4 thead tr th {
  text-align: center;
  font-size: 0.9rem !important;
  font-family: "Montserrat";
  width: 20% !important;
  white-space: nowrap;
}

@media only screen and (max-width: 1600px) {
  .salesPersonTab tbody tr td,
  .sub_tab1 tbody tr td,
  .sub_tab2 tbody tr td,
  .sub_tab3 tbody tr td,
  .sub_tab4 tbody tr td {
    text-align: center;
    font-size: 0.87rem !important;
    font-family: "Montserrat";
    width: 20% !important;
    white-space: nowrap;
  }

  .sub_tab1 thead tr th,
  .sub_tab2 thead tr th,
  .sub_tab3 thead tr th,
  .sub_tab4 tbody tr td {
    text-align: center;
    font-size: 0.87rem !important;
    font-family: "Montserrat";
    width: 20% !important;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 1500px) {
  .salesPersonTab tbody tr td,
  .sub_tab1 tbody tr td,
  .sub_tab2 tbody tr td,
  .sub_tab3 tbody tr td,
  .sub_tab4 tbody tr td {
    text-align: center;
    font-size: 0.85rem !important;
    font-family: "Montserrat";
    width: 20% !important;
    white-space: nowrap;
  }

  .salesPersonTab thead tr td,
  .sub_tab1 thead tr th,
  .sub_tab2 thead tr th,
  .sub_tab3 thead tr th,
  .sub_tab4 tbody tr td {
    text-align: center;
    font-size: 0.85rem !important;
    font-family: "Montserrat";
    width: 20% !important;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 1400px) {
  .salesPersonTab tbody tr td,
  .sub_tab1 tbody tr td,
  .sub_tab2 tbody tr td,
  .sub_tab3 tbody tr td,
  .sub_tab4 tbody tr td {
    text-align: center;
    font-size: 0.8rem !important;
    font-family: "Montserrat";
    width: 20% !important;
    white-space: nowrap;
  }

  .salesPersonTab thead tr td,
  .sub_tab1 thead tr th,
  .sub_tab2 thead tr th,
  .sub_tab3 thead tr th,
  .sub_tab4 tbody tr td {
    text-align: center;
    font-size: 0.8rem !important;
    font-family: "Montserrat";
    width: 20% !important;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 1300px) {
  .salesPersonTab tbody tr td,
  .sub_tab1 tbody tr td,
  .sub_tab2 tbody tr td,
  .sub_tab3 tbody tr td,
  .sub_tab4 tbody tr td {
    text-align: center;
    font-size: 0.72rem !important;
    font-family: "Montserrat";
    width: 20% !important;
    white-space: nowrap;
  }

  .salesPersonTab thead tr td,
  .sub_tab1 thead tr th,
  .sub_tab2 thead tr th,
  .sub_tab3 thead tr th,
  .sub_tab4 tbody tr td {
    text-align: center;
    font-size: 0.72rem !important;
    font-family: "Montserrat";
    width: 20% !important;
    white-space: nowrap;
  }
}

.underwrittingcolor {
  background-color: #e2f2fe;
}

.MuiTableCell-paddingNone {
  display: none;
}

.companyDeleteButton {
  display: flex;
  margin-bottom: 40px;
}

@media only screen and (max-width: 992px) {
  .companyDeleteButton {
    margin-bottom: 40px !important;
    margin: 0 auto;
  }
}

.specialAdminRow {
  text-align: left;
  margin-bottom: 40px !important;
  margin-top: 50px !important;
  margin-left: 20px !important;
  // display: flex;

  width: 55% !important;
}

.Reminderpoints {
  margin-top: 3rem;
}

.specialAdminRow input {
  padding: 0.2rem;
}

.disableLastName,
.disableFirstName,
.disablePhoneNumber {
  background-color: rgb(192, 191, 191) !important;
}

#quoteGenetatepage {
  font-size: 1.1rem;
}

@media only screen and (max-width: 1800px) {
  #quoteGenetatepage {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 1700px) {
  #quoteGenetatepage {
    font-size: 0.95rem;
  }
}

@media only screen and (max-width: 1600px) {
  #quoteGenetatepage {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 1600px) {
  #quoteGenetatepage {
    font-size: 0.85rem;
  }
}

@media only screen and (max-width: 1440px) {
  #quoteGenetatepage {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 1385px) {
  #quoteGenetatepage {
    font-size: 0.72rem;
  }
}

@media only screen and (max-width: 1285px) {
  #quoteGenetatepage {
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 1245px) {
  #quoteGenetatepage {
    font-size: 0.65rem;
  }

  .newquote-content {
    width: 75% !important;
  }
}

@media only screen and (max-width: 1245px) {
  #quoteGenetatepage {
    font-size: 0.67rem;
  }
}

@media only screen and (max-width: 1100px) {
  #quoteGenetatepage {
    font-size: 0.64rem;
  }
}

@media only screen and (max-width: 1100px) {
  #quoteGenetatepage {
    font-size: 0.64rem;
  }

  .newquote-content {
    //global styling for everything in the light blue background

    width: 70% !important;
  }
}

.broker-dropdown__value-container {
  width: 320px;
}

.greyout {
  background-color: rgb(194, 196, 197) !important;
}

#brokerFlow {
  input[type="radio"] {
    width: 18px;
    height: 18px;
    display: grid;
    place-content: center;
    background-color: transparent;
    cursor: pointer;
  }

  input[type="radio"]::after {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 40%;
    transform: scale(0);
    transition: 2s transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
  }

  input[type="radio"]:checked::after {
    transform: scale(1);
    border: 1px solid #427cbc;
    box-shadow: #427cbc;
    transition: 2s transform ease-in-out;
  }
}

.commisonQuestion {
  width: 87%;
  margin: auto;
  margin-top: 44px;
  margin-bottom: 30px;
}

.select_broker_dropdown {
  margin-top: 20px;
}

.table-undrques {
  margin-top: 5px;
}

@media only screen and (max-width: 1100px) {
  .commisonQuestion {
    margin-top: 20px;
  }

  .broker_dropdown {
    padding-left: 14px;
    margin-top: 20px;
    margin-bottom: 5px;
  }

  #brokerFlow {
    margin-top: -25px !important;
  }
}

.responsive-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  .locationbreakcss {
  }
}

.location-number::before {
  content: "";
  /* Default content for larger screens */
}

@media (max-width: 768px) {
  .responsive-layout {
    display: grid;
    gap: 10px;
  }

  .location-number::before {
    content: "Location - ";
    /* Content for smaller screens */
  }
}

#BrokerId {
  height: 37px;
}

#overviewcontiner,
#Pricingcontiner,
#Acknowledgecontiner,
#acknoQuestioncontiner {
  display: flex;
}

#headercontiner {
  display: flex;
}

#Peoheader {
  font-size: 30px !important;
  color: #c91351;
  margin-left: 50px;
}

#Peoheadersub {
  font-size: 30px !important;
  color: grey;
}

#bannercontiner {
  display: flex;
  margin: 0px auto;
}

#bannerid {
  margin-left: 400px !important;
  margin-bottom: 30px !important;
  margin-top: 27px !important;
  width: 410px !important;
}

#overviewRow {
  font-size: 26px !important;
  color: grey;
  font-weight: 700;
  margin-left: 50px;
  margin-top: 50px;
  margin-bottom: 25px;
}

#overViewSubContainer {
  background-color: #e0f6fb;
  height: 83px;
  display: flex;

  margin-left: 50px;
  margin-right: 40px;
  width: 960px;
}

#Disclaimercontiner {
  width: 1010px;
  display: flex;
}

#overviewSub {
  font-size: 13.8px !important;
  margin-left: 50px;
  margin-top: 10px;
}

#Pricing {
  font-size: 26px !important;
  color: grey;
  font-weight: 700;
  // margin-left: 50px;
  // margin-top: 30px;
  // margin-bottom: 45px;
}

#inputTableContainer {
  display: flex;
  margin-left: 115px;
  margin-right: 95px;
  width: 870px;
}

#inputNetratetable {
  margin: 0px auto;
  font-size: 14px !important;
}

#Acknowledge {
  color: grey;
  font-weight: 700;
  font-size: 26px !important;

  margin-left: 50px;
  margin-top: 45px;
  margin-bottom: 40px;
}

#Disclaimer {
  margin-left: 50px;
  font-size: 13.5px !important;
}

#subDisclaimer {
  font-size: 13.5px !important;
  font-weight: 800;
}

#subDisclaimer1 {
  font-size: 13.5px !important;
  font-weight: 100 !important;
}

#acknoQuestion {
  font-weight: 400;
  font-size: 14px !important;

  margin-left: 50px;
}

.pdfForm {
  font-weight: 400;
  font-size: 14px !important;
  margin-top: 35px;
  margin-left: 170px;
}

.physicalAddress {
  margin-top: 10px;
}

.compAndFein,
.compAndAddress,
.OwnerOfficeAndPropsedate {
  font-weight: 400;
  font-size: 14px !important;
  margin-top: 25px;
  margin-left: 140px;
}

.compAndAddress,
.OwnerOfficeAndPropsedate {
  margin-top: 4px !important;
}

.netRateformdetails {
  margin-bottom: 30px;
}

.twoPeopleImage,
.topImageContent {
  display: flex;
  // justify-content: center;
}

@media only screen and (min-width: 1600px) {
  .et_pb_image {
    width: 1100px !important;
    height: 800px !important;
  }
}

@media only screen and (min-width: 1700px) {
  .et_pb_image {
    width: 720px !important;
    height: 640px !important;
  }
}

@media only screen and (min-width: 1800px) {
  .et_pb_image {
    width: 1180px !important;
    height: 104% !important;
  }
}

@media only screen and (min-width: 2000px) {
  .et_pb_image {
    width: 790px !important;
    height: 770px !important;
  }
}

@media only screen and (max-width: 1599px) {
  .et_pb_image {
    width: 600px !important;
    height: 95% !important;
  }
}

@media only screen and (max-width: 1440px) {
  .et_pb_image {
    width: 800px !important;
    height: 100% !important;
  }
}

@media only screen and (max-width: 1375px) {
  .et_pb_image {
    width: 440px !important;
    height: 420px !important;
  }
}

@media only screen and (max-width: 1220px) {
  .et_pb_image {
    width: 320px !important;
    height: 300px !important;
  }
}

@media only screen and (max-width: 1100px) {
  .et_pb_image {
    width: 390px !important;
    height: 350px !important;
    margin: 50px;
  }

  .twoPeopleImage,
  .topImageContent {
    display: flex;
    justify-content: center !important;
  }
}

@media (min-width: 992px) and (max-width: 1001px) {
  .pink-header .font-family-montserrat-bold {
    text-align: left;
    width: 33%;
    font-size: 20px;
    font: bold;
    padding-left: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #ffc423;
  }
}

@media only screen and (max-width: 670px) {
  .et_pb_image {
    width: 370px !important;
    height: 300px !important;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 520px) {
  .et_pb_image {
    width: 350px !important;
    height: 300px !important;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 460px) {
  .et_pb_image {
    width: 300px !important;
    height: 280px !important;
    // margin-top: 20px;
  }
}

@media only screen and (max-width: 450px) {
  .et_pb_image {
    width: 290px !important;
    height: 270px !important;
    // margin-top: 80px;
  }
}

@media only screen and (max-width: 425px) {
  .et_pb_image {
    width: 310px !important;
    height: 290px !important;
    margin-top: 60px;
  }
}

@media only screen and (max-width: 415px) {
  .et_pb_image {
    width: 300px !important;
    height: 280px !important;
    margin-top: 55px;
  }

  nav .m-menu {
    width: 320px !important;
  }
}

@media only screen and (max-width: 370px) {
  .et_pb_image {
    width: 280px !important;
    height: 290px !important;
    margin-top: 88px;
  }
}

@media only screen and (max-width: 400px) {
  .requstedAdminRow {
    flex-wrap: wrap;
    justify-content: center;
  }

  .requestedAdminContent {
    padding-bottom: 25px;
  }
}

.row-no-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

@media only screen and (min-width: 1500px) {
  .logoreducing {
    height: 148px !important;
  }
}

@media only screen and (min-width: 1600px) {
  .logoreducing {
    height: 160px !important;
  }
}

@media only screen and (min-width: 1700px) {
  .logoreducing {
    height: 170px !important;
  }
}

@media only screen and (min-width: 1800px) {
  .logoreducing {
    height: 180px !important;
  }
}

@media only screen and (min-width: 1900px) {
  .logoreducing {
    height: 190px !important;
  }
}

@media only screen and (min-width: 2000px) {
  .logoreducing {
    height: 205px !important;
  }
}

.logoreducing {
  height: 140px;
}

.e-verify {
  color: #00b6de;
}

.Free_breakdownBox {
  margin-left: 120px;
  margin-right: 990px;
}

#feinCheckYes,
#feinCheckNo,
#physicalAddressCheckYes,
#physicalAddressCheckNo,
#e-verifyCheckYes,
#e-verifyCheckNO {
  height: 22px !important;
  width: 22px !important;
}

.dashboard-dropdown-row {
  select {
    box-shadow: 0px 2px 1px -2px rgba(0, 0, 0, 0.174),
      0px 2px 2px 0px rgba(0, 0, 0, 0.174), 0px 1px 5px 0px rgba(0, 0, 0, 0.174) !important;
    border-radius: 4px !important;
    border: none !important;
    height: 30px !important;
  }
}

.paycycle-container {
  padding: 0.75rem 0;
}

#companyprofilequestion {
  .tableQuesCol {
    width: 65%;
  }

  .tableAns {
    width: 17%;
  }
}

#companyproQuestPEOPaygo {
  width: 90% !important;

  .tableQuesCol {
    width: 58.5%;
  }
}

#companyproQuestPEOPaygo,
.tableQuesCol,
.tableAns {
  width: 100%;
  vertical-align: middle !important;
}

.notes-row {
  padding-inline: 15px;
  margin-top: -15px;

  .form-group {
    width: 100%;
  }
}

.MuiFormControl-root,
.MuiTextField-root {
  border-bottom: 1.7px solid #10110f !important;
}

.acordSelectedList {
  ol {
    margin-left: -1rem !important;
    display: flex !important;
    padding-left: 0px !important;
    position: relative;
    left: -45px;
    bottom: -50px;
  }

  ol,
  li {
    padding: 5px 15px 5px 10px !important;
    margin-left: 12px;
    margin-bottom: -10px;
    font-size: 1rem;
    font-weight: 600;
    flex-direction: row !important;
  }
}

// .tabSelected {
//   font-weight: 900;
//   font-size: 1.1rem;
//   box-shadow: 1.5px 1.5px 4px;
//   margin: 0;
//   padding: 0;
//   background: whitesmoke;
// }
.TabNameSelected {
  border-radius: 2px;
  display: flex !important;
  flex-direction: row !important;
  background-color: whitesmoke;
  box-shadow: 1.5px 1.5px 4px;
  font-size: 1.2rem;
  font-weight: 900;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #46ce74 !important;
}

.ChevronRight {
  position: absolute;
  right: 8%;
  width: 2.5%;
  bottom: 35%;
  z-index: 10;
  border: none;
  opacity: 0.5;
  border-radius: 50%;
  color: #312f2f;
  font-weight: bold;
  font-size: 30px;
  transition: 0.2s;
}

.ChevronLeft {
  position: absolute;
  left: 8%;
  width: 2.5%;
  bottom: 35%;
  z-index: 10;
  border: none;
  opacity: 0.5;
  border-radius: 50%;
  color: #312f2f;
  font-weight: bold;
  font-size: 35px;
  transition: 0.2s;
}

.ChevronLeft:hover {
  color: black !important;
  font-size: 35px;
  opacity: 0.9;
}

.ChevronRight:hover {
  color: rgb(37, 21, 21) !important;
  font-size: 35px;
  opacity: 0.9;
}

@media only screen and (max-width: 1600px) {
  .ChevronRight {
    // top: 78%;

    font-size: 30px;
    transition: 0.2s;
    width: 3%;
  }

  .ChevronLeft {
    // top: 78%;

    font-size: 30px;
    transition: 0.2s;
    width: 3%;
  }

  .ChevronLeft:hover {
    color: black !important;
    font-size: 35px;
  }

  .ChevronRight:hover {
    color: rgb(37, 21, 21) !important;
    font-size: 35px;
  }
}

@media only screen and (max-width: 1400px) {
  .ChevronRight {
    font-size: 28px;
    width: 4%;
  }

  .ChevronLeft {
    font-size: 28px;
    width: 4%;
  }

  .ChevronLeft:hover {
    color: black !important;
    font-size: 32px;
  }

  .ChevronRight:hover {
    color: rgb(37, 21, 21) !important;
    font-size: 32px;
  }
}

@media (min-width: 700px) and (max-width: 993px) {
}

@media (min-width: 425px) and (max-width: 699px) {
  /* Place your CSS styles here */
}

@media (max-width: 426px) {
  .companyProfileheadertwo {
    display: none;
  }

  .pink-header .font-family-montserrat-bold {
    text-align: center;
    width: 100%;
    font: bold;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #ffc423;
  }

  .newquote-mainbody
    .newquote-content
    #compPro
    #compProfile
    .table-undrques
    .tableQuesCol {
    width: 100% !important;
  }

  .trow1 {
    min-width: 200px;
    min-height: 100px;
  }
}

@media only screen and (max-width: 770px) {
  .ChevronRight {
    font-size: 25px;
    width: 5%;
  }

  .ChevronLeft {
    font-size: 25px;
    width: 5%;
  }

  .ChevronLeft:hover {
    color: black !important;
    font-size: 26px;
  }

  .ChevronRight:hover {
    color: rgb(37, 21, 21) !important;
    font-size: 26px;
  }
}

.btnFormFinal {
  border: 1px solid grey;
  border-radius: 5px;
  font-size: 14px;
  padding: 4px 5px 4px 5px;
}

.MuiInputBase-input,
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  font-size: 13px !important;
}

.accord-container-mobile {
  width: 15rem;
  height: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0px 5px 12px grey;
  background-color: #faa541;
  font-size: 1rem;
  font-weight: bolder;
}

.spinner {
  width: 15rem;
  margin: auto;
  margin-top: 20px;
}

//Comments box styling
.comment-btn {
  border: 1px solid #ffbd16;
  border-radius: 5px;
  font-size: 20px !important;
  margin-left: 2px !important;
}

.comment-btn:hover {
  border: 1px solid #ffffff;
  background-color: #ffbd16;
  color: #ffffff;
  border-radius: 5px;
}

.MuiDialogTitle-root {
  padding: 17px 85px !important;
}

.disabled_comment-btn {
  border: 1px solid #ffbd16;
  border-radius: 5px;
  font-size: 20px !important;
  margin-left: 2px !important;
}

.engage-Uw-ques {
  display: flex;
  justify-content: center;
  align-items: center;

  table {
    width: 80% !important;

    .tableQuesCol {
      width: 41% !important;
    }

    .customtableAns {
      // input[type="radio"] {
      //   box-sizing: border-box;
      //   appearance: none;

      //   border: 1.5px solid #ccc;
      //   border-radius: 2px;

      //   background-color: white !important;

      //   width: 16px !important;
      //   min-width: 16px !important;
      //   height: 16px !important;
      // }

      // input[type="radio"]:checked {
      //   background-color: #427cbc !important;
      // }

      .selectstyle {
        margin-right: 5px;
        height: 40px;
        width: 117px;
        font-size: 14px;

        option {
          font-size: 15px;
        }

        //border: 1px solid black;
      }

      label {
        margin-right: 5px;
      }
    }

    .tableAns {
      padding: 0 !important;

      div:nth-child(2) {
        margin-left: 10px;
      }

      .form-check-inline {
        // flex-flow: row-reverse;
      }

      input[type="radio"] {
        min-width: 20px;
        //box-sizing: border-box;
        //appearance: none;
        width: 20px;
        height: 20px;
        display: grid;
        place-content: center;
        background-color: transparent;
        cursor: pointer;
      }

      input[type="radio"]:after {
        content: "";
        width: 0.65em;
        height: 0.65em;
        border-radius: 40%;

        transition: 2s transform ease-in-out;
        box-shadow: inset 1em 1em var(--form-control-color);
      }

      input[type="radio"]:checked:after {
        transform: scale(1);
        border: 1px solid #427cbc;
        box-shadow: #427cbc;
        //  transition: 2s transform ease-in-out;
      }

      // input[type="radio"]:checked {
      //   background-color: #427cbc !important;
      // }

      label {
        margin-right: 5px;
      }
    }
  }
}

.selectstyle {
  margin-right: 5px;
  height: 27px;
  width: 117px;
  font-size: 14px;

  option {
    font-size: 15px;
  }

  //border: 1px solid black;
}

.subnav {
  ul {
    li {
      padding-inline: 15px !important;
    }
  }
}

.border-bottom-color {
  border-bottom: groove;
}

.salesDashboard {
  .MuiButtonBase-root.MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: black !important;
  }

  .MuiButtonBase-root.MuiTableSortLabel-root {
    color: black !important;
  }
}

.custom-file-upload {
  display: flex;
  width: 94%;
  // margin: auto;
  justify-content: flex-end;
}

.exclamation-icon {
  cursor: pointer;
  display: flex;
  width: 86%;
  margin-top: -20%;
  // padding-bottom: 0.5%;
  margin: auto;
  justify-content: flex-end;
}

#file-upload {
  display: none;
}

.upload-button {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  border-radius: 5px;
  margin-left: 10px;
}

.button-save-style {
  background-color: #427cbc;
  color: white;
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 0.72rem;
  box-shadow: rgba(0, 0, 0, 0.3) 2.95px 2.95px 6.6px;
}

.button-save-style:hover {
  background-color: #204268;
  color: white;
}

.button-save-style:focus {
  box-shadow: none !important;
}

.button-delete-row {
  background-color: white;
  border: none;
  padding: 0;
  margin: 0;
}

.dynamic-render-container {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 100vh; /* Full viewport height to center vertically */
}

// Landing page responsive

/* For mobile phones */
@media (max-width: 767px) {
  .home-landing-page .login-container {
    right: 0px;
  }

  .home-landing-page .home-banner .banner-container .white-box {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .zonefirst {
    display: grid;
    margin-top: 50px;
    // padding-left: 10px;

    .image-banner-style {
      display: flex;
      justify-content: center;
    }
  }

  .contact-form-client {
    padding-top: 30px;
    width: 100%;
    min-width: 100%;
  }

  .headers-companies {
    padding-top: 60px;
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }

  .image-banner-style {
    width: 100%;
    min-width: 100%;
  }
}

/* Mobile view */
@media screen and (max-width: 1024px) {
  .zonefirst {
    // padding-left: 5px;
  }

  .contact-form-client {
    padding-top: 20px;
    width: 100%;
    min-width: 100%;
  }

  .headers-companies {
    text-align: center;
    margin-top: 100px;
    padding-top: 40px;
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }

  .header-sompany-logo-style {
    width: 100%;
  }

  .image-banner-style {
    width: 100%;
    min-width: 100%;
  }
}

.notePart {
  display: flex;
  flex-direction: column;
  padding-top: 200px;
}

.campaignDateBox {
  border: none;
  border-bottom: 2px solid black;
  width: 100px;
  // padding: 5px;
  outline: none;
}

.my-popup-class {
  background-color: white !important ;
  border: 1px solid black;
  border-radius: 10px;
  width: 40%;
  font-family: "MontserratRegular" !important;
  font-size: 12px !important ;
}

.my-title-class {
  color: black;
  font-weight: bold;
}

.my-icon-class {
  color: #e76f51;
}
